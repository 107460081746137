import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import { Formik, Form, Field } from 'formik'

class AutoSave extends React.PureComponent {
	static propTypes = {
		values: PropTypes.object.isRequired,
		mount: PropTypes.func,
		update: PropTypes.func
	}

	componentDidMount() {
		this.props.mount(this.props.values)
	}

	componentDidUpdate() {
		this.props.update(this.props.values)
	}

	render() {
		return null
	}
}

class AttorneySearchForm extends React.PureComponent {
	static propTypes = {
		onChange: PropTypes.func,
		onBootstrap: PropTypes.func,
		children: PropTypes.func,
		location: PropTypes.object,
		innerRef: PropTypes.func
	}

	state = {
		params: qs.parse(this.props.location.search)
	}

	get initialValues() {
		const props = ['name', 'rank', 'locationId', 'practiceAreaId']
		return props.reduce((acc, prop) => {
			if (this.state.params[prop]) {
				acc[prop] = this.state.params[prop]
			} else {
				acc[prop] = ''
			}
			return acc
		}, {})
	}

	render() {
		return (
			<Formik initialValues={this.initialValues} innerRef={this.props.innerRef}>
				{({ values }) => (
					<Form>
						<AutoSave values={values} mount={this.props.onBootstrap} update={this.props.onChange} />
						{React.Children.map(this.props.children({ Field }), (child) => (
							<div>{child}</div>
						))}
					</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(AttorneySearchForm)
