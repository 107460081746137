import omit from 'lodash/omit'
const AllRoutes = omit(require('./**/*.js'), 'index')

// Recursive function to import every route into an exportable array
const importedRoutes = (() => {
	const routes = []
	let pageNotFound
	function registerRoutes(tree) {
		const isRoute = !!tree.routeConfig
		if (isRoute) {
			if (tree.routeConfig.path === '*') {
				pageNotFound = tree
			} else {
				routes.push(tree)
			}
		} else {
			for (const subTree of Object.values(tree)) {
				registerRoutes(subTree)
			}
		}
	}
	registerRoutes(AllRoutes)
	if (pageNotFound) {
		routes.push(pageNotFound)
	}
	return routes
})()

export default importedRoutes
