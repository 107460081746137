module.exports={
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "variableDefinitions": [],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "entries"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "section"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "practiceAreas",
                  "block": false
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "orderBy"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "title ASC",
                  "block": false
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "title"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "slug"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "practiceAreas_practiceAreas_Entry"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "practiceAreaLeadImage"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "title"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "url"
                              },
                              "arguments": [],
                              "directives": [
                                {
                                  "kind": "Directive",
                                  "name": {
                                    "kind": "Name",
                                    "value": "transform"
                                  },
                                  "arguments": [
                                    {
                                      "kind": "Argument",
                                      "name": {
                                        "kind": "Name",
                                        "value": "handle"
                                      },
                                      "value": {
                                        "kind": "StringValue",
                                        "value": "service",
                                        "block": false
                                      }
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "Field",
                              "alias": {
                                "kind": "Name",
                                "value": "searchResultThumbUrl"
                              },
                              "name": {
                                "kind": "Name",
                                "value": "url"
                              },
                              "arguments": [],
                              "directives": [
                                {
                                  "kind": "Directive",
                                  "name": {
                                    "kind": "Name",
                                    "value": "transform"
                                  },
                                  "arguments": [
                                    {
                                      "kind": "Argument",
                                      "name": {
                                        "kind": "Name",
                                        "value": "handle"
                                      },
                                      "value": {
                                        "kind": "StringValue",
                                        "value": "searchResult",
                                        "block": false
                                      }
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "serviceContent"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "typeCondition": {
                                "kind": "NamedType",
                                "name": {
                                  "kind": "Name",
                                  "value": "serviceContent_contentSection_BlockType"
                                }
                              },
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "headline"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "body"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 409
  }
};