import React from 'react'

export default function () {
	return (
		<svg width="22px" height="12px" viewBox="0 0 22 12" version="1.1">
			<g
				stroke="none"
				strokeWidth="1"
				fill="#ffffff"
				fillRule="evenodd"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<g transform="translate(-388.000000, -646.000000)" stroke="#75757C" strokeWidth="2">
					<polyline points="389 647 399 657 409 647"></polyline>
				</g>
			</g>
		</svg>
	)
}
