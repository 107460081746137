import React from 'react'
import PropTypes from 'prop-types'
import * as icons from './icons/*.js'

class Icon extends React.PureComponent {
	static propTypes = {
		name: PropTypes.oneOf(Object.keys(icons))
	}

	render() {
		const IconSvg = icons[this.props.name]

		return <IconSvg {...this.props} />
	}
}

export default Icon
