import React from 'react'

export default function () {
	return (
		<svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
			<path
				fill="#D4DDE7"
				fillRule="nonzero"
				d="M19 0c10.486 0 19 8.274 19 18.5S29.486 37 19 37C8.498 37 0 28.71 0 18.5 0 8.274 8.514 0 19 0Zm-2.938 10H11l6.637 9.451L11 27h1.5l5.803-6.6 4.635 6.6H28l-6.883-9.802L27.446 10h-1.5l-5.495 6.25L16.062 10Zm-.92 1 4.014 6.109.626.95L25 26h-2.142L18.6 19.52l-.625-.95L13 11h2.142Z"
			/>
		</svg>
	)
}
