import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { Helmet } from 'react-helmet'

import Masthead from '@/components/Masthead'
import Spinner from '@/components/Spinner'

class StandardPageLayout extends React.Component {
	static Masthead = Masthead

	static defaultProps = {
		loading: false
	}

	static propTypes = {
		pageTitle: PropTypes.string,
		className: PropTypes.string,
		loading: PropTypes.bool,
		masthead: PropTypes.element,
		children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
		preBody: PropTypes.node,
		preFooter: PropTypes.node
	}

	static LayoutSection({ headline, children }) {
		return (
			<section className={styles.layoutSection}>
				{headline && <div className={styles.layoutSectionHeadline}>{headline}</div>}
				{children}
			</section>
		)
	}

	render() {
		const { pageTitle, masthead, children, preBody, preFooter, loading } = this.props
		return (
			<>
				<Helmet>
					<title>{pageTitle}</title>
				</Helmet>
				<div className={classnames(styles.standardPageLayout, this.props.className)}>
					{masthead}
					{loading ? (
						<div className={styles.loading}>
							<Spinner />
						</div>
					) : (
						<>
							{preBody}
							<div className={styles.body}>{typeof children === 'function' ? children() : children}</div>
							{preFooter}
						</>
					)}
				</div>
			</>
		)
	}
}

export default StandardPageLayout
