import React from 'react'

export default function () {
	return (
		<svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g
					id="footer-desktop-blue"
					transform="translate(-1124.000000, -82.000000)"
					fill="#D4DDE7"
					fillRule="nonzero"
				>
					<g id="social" transform="translate(1070.000000, 82.000000)">
						<g id="Group" transform="translate(54.000000, 0.000000)">
							<path
								d="M19,0 C29.5021796,0 38,8.27419355 38,18.5 C38,28.7258065 29.4856146,37 19,37 C8.4978204,37 0,28.7096774 0,18.5 C0,8.27419355 8.51438535,0 19,0 Z M24,8.14345638 C23.3699515,8.06375839 22.4975767,8.0159396 21.3828756,8 C20.0420032,8 18.9757674,8.38255034 18.1680129,9.1635906 C17.3602585,9.94463087 16.9563813,11.0444631 16.9563813,12.4630872 L16.9563813,14.9496644 L14,14.9496644 L14,18.3288591 L16.9563813,18.3288591 L16.9563813,27 L20.4943457,27 L20.4943457,18.3288591 L23.4345719,18.3288591 L23.8869144,14.9496644 L20.4943457,14.9496644 L20.4943457,12.7978188 C20.4943457,12.2558725 20.6074313,11.841443 20.8336026,11.5704698 C21.0597738,11.2994966 21.5121163,11.1560403 22.174475,11.1560403 L24,11.1560403 L24,8.14345638 Z"
								id="Combined-Shape"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
