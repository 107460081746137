import React from 'react'

export default function () {
	return (
		<svg width="27px" height="21px" viewBox="0 0 27 21" version="1.1">
			<path d="M27 19c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h23c1.1 0 2 .9 2 2v17z" fill="#1B3658" />
			<path fill="#FFF" d="M24.3 2.8v15.4H2.7V2.8h21.6" />
			<g transform="translate(4 5)" fill="#1B3658">
				<ellipse cx={4.3} cy={2.9} rx={2} ry={2.1} />
				<path d="M.4 10.2c0-2.2 1.7-4 3.9-4s3.9 1.8 3.9 4H.4zM10.1 1H18v1.4h-7.9zM10.1 4.8H18v1.4h-7.9zM10.1 8.7H18v1.4h-7.9z" />
			</g>
		</svg>
	)
}
