import React from 'react'
import Classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { CraftEntries, StandardPageLayout, Container, Headline } from '@/components/common'
import { get } from 'lodash'
import qs from 'query-string'
import Debounce from '@/components/Debounce'
import { fuseSearch } from '@/components/FuseSearch'
import Spinner from '@/components/Spinner'
import HeadlineWithNav from '@/components/HeadlineWithNav'
import SearchInput from '@/components/SearchInput'

import AttorneyList from '@/components/AttorneyList'
import LocationsList from '@/components/LocationsList'
import PracticeAreasList from '@/components/PracticeAreasList'
import ArticlesList from '@/components/ArticlesList'

const CONFIG = [
	{
		id: 'attorneys',
		label: 'Lawyers',
		gql: require('@/craft/queries/attornies.gql'),
		render: (items, limited) => <AttorneyList attorneys={items} limit={limited ? 8 : null} />,
		searchReducer: (item) => {
			const keywords = [
				...[item.attorneyBusinessRank],
				...item.practiceAreas.map((m) => m.title),
				...(item.accolades || []).map((m) => m.title),
				...(item.courtAdmissions || []).map((m) => m.title),
				...(item.barAdmissions || []).map((m) => m.title),
				...(item.education || []).map((m) => m.title),
				...(item.professionalActivities || []).map((m) => m.title),
				...(item.languages || []).map((m) => m.title)
			]

			return {
				item,
				title: item.title,
				keywords: keywords.join(' '),
				extended: item.attorneyBio
			}
		}
	},
	{
		id: 'locations',
		label: 'Locations',
		gql: require('@/craft/queries/locations.gql'),
		render: (items, limited) => <LocationsList items={items} limit={limited ? 4 : null} />,
		searchReducer: (item) => {
			return {
				item,
				title: item.title,
				keywords: ''
			}
		}
	},
	{
		id: 'practiceAreas',
		label: 'Practice Areas',
		gql: require('@/craft/queries/practiceAreas.gql'),
		render: (items, limited) => <PracticeAreasList items={items} limit={limited ? 4 : null} />,
		searchReducer: (item) => {
			const keywords = item.serviceContent.map((content) => {
				return `${content.headline} ${content.body}`
			})
			return {
				item,
				title: item.title,
				keywords
			}
		}
	},
	{
		id: 'articles',
		label: 'News & Insights',
		gql: require('@/craft/queries/articles.gql'),
		render: (items, limited) => <ArticlesList articles={items} limit={limited ? 4 : null} />,
		searchReducer: (item) => {
			const keywords = (() => {
				const retVal = []
				for (const prop of ['practiceAreas', 'attorneys']) {
					retVal.push(...item[prop].map((m) => m.title))
				}
				retVal.push(...item.body.map((body) => body.richText))
				return retVal.join(' ')
			})()
			return {
				item,
				title: item.title,
				keywords
			}
		}
	}
]

const QUERIES = CONFIG.reduce((acc, { id, gql }) => {
	acc[id] = {
		query: gql
	}
	return acc
}, {})

function SearchFuse({ results, query, render }) {
	const filtered = React.useMemo(() => {
		return Object.entries(results).reduce((acc, [id, items]) => {
			const config = CONFIG.find((group) => group.id === id)
			const searchables = items.map(config.searchReducer)
			acc[id] = fuseSearch(searchables, query, {
				keys: [
					{
						name: 'title',
						weight: 1
					},
					{
						name: 'keywords',
						weight: 0.75
					},
					{
						name: 'extended',
						weight: 0.5
					}
				]
			})
			return acc
		}, {})
	}, [query])
	return render(filtered)
}

const SearchNav = React.forwardRef(({ active, onChange, results }, ref) => {
	const allResultsCount = Object.values(results).flat().length
	const Button = ({ value, label, count }) => {
		return (
			<button
				className={Classnames(styles.buttonLink, { [styles.active]: active === value })}
				onClick={() => {
					onChange(value)
				}}
				children={
					<>
						{label} ({count})
					</>
				}
				disabled={count < 1}
			/>
		)
	}
	Button.propTypes = {
		value: PropTypes.string,
		label: PropTypes.string,
		count: PropTypes.number
	}
	return (
		<div className={styles.nav} ref={ref}>
			<div className={styles.nav__all}>
				<Button value={null} label="All Results" count={allResultsCount} />
			</div>
			<div className={styles.nav__nodes}>
				{CONFIG.map(({ id, label }) => {
					const count = (results[id] || []).length
					return <Button key={id} value={id} label={label} count={count} />
				})}
			</div>
		</div>
	)
})

SearchNav.propTypes = {
	active: PropTypes.string,
	onChange: PropTypes.func,
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string
		})
	),
	results: PropTypes.shape({})
}

class SearchRoute extends React.PureComponent {
	static defaultProps = {
		location: {
			search: ''
		}
	}

	static propTypes = {
		location: PropTypes.shape({
			search: PropTypes.string
		}),
		history: PropTypes.shape({
			replace: PropTypes.func
		})
	}

	state = {
		searchQueryInput: this.searchQuery,
		activeFilter: null
	}

	updateSearchQueryParam = (q) => {
		this.props.history.replace({
			search: qs.stringify({ q })
		})
	}

	setActiveFilter = (activeFilter) => {
		if (this.$el) {
			const { top } = this.$el.getBoundingClientRect()
			if (top <= 0) {
				const y = top + window.pageYOffset - 16
				window.scrollTo(0, y)
			}
		}
		this.setState({
			activeFilter
		})
	}

	get searchQuery() {
		return get(qs.parse(this.props.location.search), 'q', '')
	}

	render() {
		return (
			<CraftEntries
				queries={QUERIES}
				render={(isReady, results) => {
					return (
						<StandardPageLayout
							pageTitle={'Search'}
							loading={!isReady}
							masthead={
								<StandardPageLayout.Masthead
									title="Search"
									backgroundImage={require('./masthead_bg.jpg')}
								/>
							}
							children={() => (
								<Container>
									<Debounce
										ms={500}
										func={this.updateSearchQueryParam}
										render={({ debounced, pending }) => (
											<div
												className={styles.search}
												ref={(el) => {
													this.$el = el
												}}
											>
												<div className={styles.search__input}>
													<SearchInput
														value={this.state.searchQueryInput}
														onChange={(e) => {
															const { value = '' } = e.target
															this.setState({
																searchQueryInput: value
															})
															debounced(value)
														}}
													/>
												</div>
												{this.state.searchQueryInput && (
													<>
														{pending ? (
															<div className={styles.spinner}>
																<Spinner />
															</div>
														) : (
															<SearchFuse
																results={results}
																query={this.state.searchQueryInput}
																render={(filtered) => (
																	<>
																		<SearchNav
																			active={this.state.activeFilter}
																			onChange={this.setActiveFilter}
																			results={filtered}
																			ref={(el) => {
																				this.searchNavEl = el
																			}}
																		/>
																		{CONFIG.map((group) => {
																			const items = filtered[group.id]
																			let allowRender = items.length > 0
																			if (this.state.activeFilter) {
																				allowRender =
																					this.state.activeFilter === group.id
																			}
																			if (allowRender) {
																				return (
																					<div
																						className={styles.group}
																						key={group.id}
																					>
																						<HeadlineWithNav
																							className={
																								styles.group__headline
																							}
																							headline={
																								<Headline
																									tag="h2"
																									theme="hd-2"
																								>
																									{group.label}
																								</Headline>
																							}
																							navLink={
																								this.state
																									.activeFilter ? null : (
																									<button
																										className={
																											styles.buttonLink
																										}
																										onClick={() => {
																											this.setActiveFilter(
																												group.id
																											)
																										}}
																									>
																										View all{' '}
																										{group.label}
																									</button>
																								)
																							}
																						/>
																						{group.render(
																							items.map((i) => i.item),
																							!this.state.activeFilter
																						)}
																					</div>
																				)
																			}
																			return null
																		})}
																	</>
																)}
															/>
														)}
													</>
												)}
											</div>
										)}
									/>
								</Container>
							)}
						/>
					)
				}}
			/>
		)
	}
}

export const routeConfig = {
	path: '/search',
	exact: true
}

export default SearchRoute
