import React from 'react'
import StandardPageLayout from '@/components/StandardPageLayout'
import { Headline, Container, RichText, VideoPlayer } from '@/components/common'
const { LayoutSection } = StandardPageLayout

class OurFirm extends React.PureComponent {
	render() {
		const layoutConfig = {
			pageTitle: 'Our Firm',
			masthead: (
				<StandardPageLayout.Masthead title="Our Firm" backgroundImage={require('./img/masthead_bg.jpg')} />
			)
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				<Container>
					<LayoutSection
						headline={
							<Headline tag="h2" theme="hd-1">
								A proud history of exceptional service
							</Headline>
						}
					>
						<RichText>
							<p>
								At Ropers Majeski, we pride ourselves on the results we deliver and the value we add to
								every client relationship. Our lawyers advise clients on complex litigation and
								sophisticated business transactions in a broad range of industries. We solve problems,
								structure deals, and represent our clients’ best interests in everything we do.
							</p>
						</RichText>
					</LayoutSection>
					<LayoutSection>
						<VideoPlayer.AWS asset="Ropers_Majeski_Strengths_I_web" />
					</LayoutSection>
					<LayoutSection
						headline={
							<Headline tag="h2" theme="hd-2">
								Representing our clients in their critical deals and complex cases
							</Headline>
						}
					>
						<RichText>
							<p>
								Ropers Majeski began more than 70 years ago, and over the years we have broadened our
								legal portfolio to include construction, real estate, employment law, intellectual
								property, and commercial litigation and transaction services. We have grown from our
								home office in Silicon Valley by adding offices in San Jose, Los Angeles, Las Vegas,
								Minneapolis, New York, Seattle, and Paris, and an affiliate office in Hong Kong.
							</p>
							<p>
								Our clients include leading corporations, startups and emerging companies, and
								municipalities and government agencies. Large and established or new and feisty, Ropers
								Majeski’s strategic legal representation helps our clients meet their goals.
							</p>
						</RichText>
					</LayoutSection>
				</Container>
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/our-firm',
	exact: true
}

export default OurFirm
