import React from 'react'
import PropTypes from 'prop-types'
import { Query } from '@/craft'

class CraftEntries extends React.PureComponent {
	static propTypes = {
		render: PropTypes.func.isRequired,
		queries: PropTypes.object.isRequired
	}

	_isMounted = false

	state = {
		ready: false,
		results: null
	}

	async componentDidMount() {
		const { queries } = this.props
		this._isMounted = true
		const results = {}
		const promises = Object.entries(queries).reduce((all, [prop, config]) => {
			all[prop] = Query({
				query: config.query,
				variables: config.variables
			})
			return all
		}, {})
		for (const [prop, promise] of Object.entries(promises)) {
			const config = queries[prop]
			let result = await promise
			if (config.solo) {
				result = result[0]
			}
			if (config.transform) {
				result = config.transform(result)
			}
			results[prop] = result
		}
		if (this._isMounted) {
			this.setState({
				ready: true,
				results
			})
		}
	}

	async componentWillUnmount() {
		this._isMounted = false
	}

	render() {
		return this.props.render(this.state.ready, this.state.results || {}) || null
	}
}

export default CraftEntries
