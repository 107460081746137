import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'

@observer
class Logo extends React.Component {
	static defaultProps = {
		inverse: false
	}

	static propTypes = {
		inverse: PropTypes.bool,
		render: PropTypes.node
	}

	render() {
		return (
			<Link to="/" className={`${styles.logo} ${Mq.mobile ? styles.small : ''}`}>
				{this.props.render ||
					(this.props.inverse ? (
						<img src={require('./ropers_inverse.png')} />
					) : (
						<img src={require('./ropers.png')} />
					))}
			</Link>
		)
	}
}

export default Logo
