import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'
import Icon from '@/components/Icon'

class SelectInput extends React.PureComponent {
	static defaultProps = {
		options: [],
		valueProp: 'value',
		labelProp: 'label'
	}

	static propTypes = {
		options: PropTypes.array.isRequired,
		valueProp: PropTypes.string,
		labelProp: PropTypes.string,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		className: PropTypes.string
	}

	get label() {
		const option = this.props.options.find((option) => option[this.props.valueProp] === this.props.value)
		if (option) {
			return option[this.props.labelProp]
		}
		return null
	}

	render() {
		const { options, valueProp, labelProp, placeholder, className, ...passThroughProps } = this.props
		return (
			<div className={classnames(styles.select, className)}>
				<div className={styles.inner}>
					<div className={classnames(styles.label, { [styles.placeholder]: !this.props.value })}>
						{this.label || placeholder}
					</div>
					<div className={styles.downarrow}>
						<Icon name="downarrow" />
					</div>
				</div>
				<select {...passThroughProps} className={styles.selectElement}>
					<option value=""></option>
					{options.map((option) => (
						<option value={option[valueProp]} key={option[valueProp]}>
							{option[labelProp]}
						</option>
					))}
				</select>
			</div>
		)
	}
}

export default SelectInput
