import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

class GAPageView extends React.Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	}

	componentDidMount() {
		this.emitPageView()
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.emitPageView()
		}
	}

	emitPageView = () => {
		if (window.gtag) {
			window.gtag('config', window.gaUA, { page_path: this.props.location.pathname })
		}
	}

	render() {
		return null
	}
}

export default withRouter(GAPageView)
