import React from 'react'
import PropTypes from 'prop-types'
import StandardPageLayout from '@/components/StandardPageLayout'
import { Container, RichText } from '@/components/common'
const { LayoutSection } = StandardPageLayout
import withCraftEntries from '@/components/withCraftEntries'

class PrivacyPolicy extends React.PureComponent {
	static propTypes = {
		entriesReady: PropTypes.bool,
		content: PropTypes.shape({
			body: PropTypes.string
		})
	}

	render() {
		const layoutConfig = {
			pageTitle: 'Privacy Policy',
			loading: !this.props.entriesReady,
			masthead: (
				<StandardPageLayout.Masthead
					title="Privacy Policy"
					backgroundImage={require('./img/masthead_bg.jpg')}
				/>
			)
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				{this.props.entriesReady && (
					<Container>
						<LayoutSection>
							<RichText html={this.props.content.body} />
						</LayoutSection>
					</Container>
				)}
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/privacy',
	exact: true
}

export default withCraftEntries(PrivacyPolicy, {
	content: {
		query: require('@/craft/queries/privacy.gql'),
		solo: true
	}
})
