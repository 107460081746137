import React from 'react'
import StandardPageLayout from '@/components/StandardPageLayout'
import Careers from '@/components/Careers'
import { Container, RichText, VideoPlayer, Button } from '@/components/common'

const careersMailLink = <a href="mailto:careers@ropers.com">careers@ropers.com</a>

class CareersPage extends React.PureComponent {
	render() {
		const layoutConfig = {
			pageTitle: 'Careers',
			masthead: <StandardPageLayout.Masthead title="Careers" backgroundImage={require('./masthead_bg.jpg')} />
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				<StandardPageLayout.LayoutSection>
					<Container>
						<RichText>
							<p>
								Ropers Majeski’s longstanding core values of integrity, passion for the law, excellent
								client service, and exceptional value are the foundation of our firm’s culture.  Ropers
								has a strong reputation for providing high-quality legal work for an array of clients
								ranging from traditional industries to emerging technology. We provide professionals at
								every level the opportunity for growth, support, and freedom needed to excel in their
								careers.
							</p>
						</RichText>
					</Container>
				</StandardPageLayout.LayoutSection>
				<StandardPageLayout.LayoutSection>
					<Container>
						<VideoPlayer.AWS asset="Ropers_Majeski_GPTW_E_web" />
					</Container>
				</StandardPageLayout.LayoutSection>
				<StandardPageLayout.LayoutSection>
					<Container>
						<Careers>
							<Careers.Category
								title="Experienced Lawyers"
								blurb={
									<p>
										Lawyers join Ropers Majeski for the same reason they decided to practice law: to
										solve challenging legal problems for diverse and dynamic clients. We have
										created a collegial and empowering work culture, fostering an environment that
										encourages entrepreneurialism and personal development. Many of our senior
										partners began their careers at Ropers, and we also seek lateral lawyer
										candidates and groups who will share our firm values and commitment to client
										service and growth.
									</p>
								}
								cta={
									<Button
										tag="a"
										href="https://roperscareers.viglobalcloud.com/viRecruitSelfApply/RecDefault.aspx?FilterREID=2"
										target="_blank"
									>
										View Open Positions
									</Button>
								}
							/>
							<Careers.Category
								title="Business Professionals"
								blurb={
									<p>
										Our extraordinary business professionals are the backbone of our success. We
										offer a collaborative and collegial office culture, while constantly striving to
										improve and expand our skills and experience. Ropers is committed to hiring top
										professionals with a commitment to exceptional client service and fostering a
										positive work environment. Our business professionals are supported throughout
										the stages of their careers, with opportunities for growth and personal
										development.
									</p>
								}
								image={require('./business_professionals.jpg')}
								reverse={true}
								cta={
									<Button
										tag="a"
										href="https://roperscareers.viglobalcloud.com/viRecruitSelfApply/RecDefault.aspx?FilterREID=3"
										target="_blank"
									>
										View Open Positions
									</Button>
								}
							/>
							<Careers.Category
								title="Law Students"
								blurb={
									<p>
										We offer new lawyers and law school students a strong firm identity and
										reputation, a diverse practice, and a record of steady and strategic growth. Our
										teams are supportive and agile, allowing young lawyers early at-bats and
										valuable experience at the start of their careers. We engage in professional
										development and training efforts to help develop and hone hands-on skills,
										including client and business development.
									</p>
								}
								image={require('./law_students.jpg')}
								cta={
									<Button
										tag="a"
										href="https://roperscareers.viglobalcloud.com/viRecruitSelfApply/RecDefault.aspx?FilterREID=2&FilterJobCategoryID=1"
										target="_blank"
									>
										View Open Positions
									</Button>
								}
							/>
							<Careers.Category
								title="Search Firms"
								blurb={
									<>
										<p>
											We accept submissions only from agencies with a valid placement agreement
											with the firm. Contracted agencies may submit candidates for any open
											opportunities that appear via the Search Firms Opportunities link below.
											Lateral partner candidates can also be submitted for consideration via this
											link. Submission materials will not be considered received until submitted
											via the portal. If your agency has an agreement with Ropers Majeski but
											cannot locate its login ID, please contact {careersMailLink}.
										</p>
										<p>
											If your agency does not have an agreement with the firm and you wish to
											introduce a candidate, please send a blind summary of your candidate to{' '}
											{careersMailLink} for consideration. No identifying information should be
											presented at that time. We do not accept unsolicited resumes from agencies
											and consider any resume (CV) received from an agency without prior approval
											from our Talent Acquisition Department to be unsolicited and gratuitous.
											Ropers Majeski will not recognize unauthorized submissions for purposes of
											"ownership" of the candidate. You will not be entitled to receive any
											compensation as a result of a decision by Ropers Majeski to offer employment
											to any such candidate unless a fee agreement is in place between Ropers
											Majeski and your search firm.
										</p>
										<p>
											All recruiting inquiries should be directed to {careersMailLink} and not to
											any firm attorney. We will not pay a fee to any agency that does not follow
											these guidelines. Contact {careersMailLink} with any questions related to
											these guidelines.
										</p>
									</>
								}
								reverse={true}
								image={require('./search_firms.jpg')}
								cta={
									<>
										<div>
											<Button
												tag="a"
												href="https://roperscareers.viglobalcloud.com/viRecruitSearchFirm/RecDefault.aspx?FilterREID=2"
												target="_blank"
											>
												Search Firm Opportunities – Attorneys
											</Button>
										</div>
										<div style={{ marginTop: 10 }}>
											<Button
												tag="a"
												href="https://roperscareers.viglobalcloud.com/viRecruitSearchFirm/RecDefault.aspx?FilterREID=3"
												target="_blank"
											>
												Search Firm Opportunities – Business Professionals
											</Button>
										</div>
									</>
								}
							/>
							<RichText>
								<p>
									We are always pleased to hear from talented lawyers, professionals, and law students
									interested in joining our firm. If you are interested in pursuing a career at Ropers
									Majeski but don't see a current job listing that fits your skills, please click the
									appropriate link above and complete our "future opportunities" application.
								</p>
							</RichText>
						</Careers>
					</Container>
				</StandardPageLayout.LayoutSection>
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/careers',
	exact: true
}

export default CareersPage
