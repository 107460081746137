import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import AppStore from '@/store/app'
import Mq from '@/store/mq'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'
import { get } from 'lodash'

import StandardPageLayout from '@/components/StandardPageLayout'
import { Container, Headline } from '@/components/common'
import AttorneySearchForm from '@/components/AttorneySearchForm'
import AttorneyAlphaNav from '@/components/AttorneyAlphaNav'
import FuseSearch from '@/components/FuseSearch'
import SelectInput from '@/components/SelectInput'
import AttorneyList from '@/components/AttorneyList'
import withCraftEntries from '@/components/withCraftEntries'

const RANKS = [
	{ value: 'PARTNER', label: 'Partner' },
	{ value: 'OF_COUNSEL', label: 'Of Counsel' },
	{ value: 'SENIOR_ASSOCIATE', label: 'Senior Associate' },
	{ value: 'ASSOCIATE', label: 'Associate' }
]

class Filter extends React.PureComponent {
	static propTypes = {
		data: PropTypes.array.isRequired,
		criteria: PropTypes.object,
		children: PropTypes.func
	}
	render() {
		const { data, criteria } = this.props
		const filtered = data.filter((item) => {
			const conditions = [true]
			if (criteria.fromLetter) {
				conditions.push(item.lastName.toLowerCase().startsWith(criteria.fromLetter))
			}
			if (criteria.rank) {
				conditions.push(item.attorneyBusinessRank === criteria.rank)
			}
			if (criteria.locationId) {
				conditions.push(item.locations.map((l) => l.id).includes(criteria.locationId))
			}
			if (criteria.practiceAreaId) {
				conditions.push(item.practiceAreas.map((l) => l.id).includes(criteria.practiceAreaId))
			}
			return conditions.every((c) => c === true)
		})
		return this.props.children(filtered)
	}
}

class FilteredAttorneyList extends React.PureComponent {
	static propTypes = {
		attorneys: PropTypes.array
	}

	render() {
		return (
			<Container>
				{this.props.attorneys.length ? (
					<AttorneyList attorneys={this.props.attorneys} />
				) : (
					<>
						<Headline tag="h4" theme="hd-4">
							No Results!
						</Headline>
						<p>Please update your search and try again.</p>
					</>
				)}
			</Container>
		)
	}
}

@observer
class OurTeam extends React.PureComponent {
	static propTypes = {
		entriesReady: PropTypes.bool.isRequired,
		attorneys: PropTypes.array,
		practiceAreas: PropTypes.array,
		location: PropTypes.object
	}

	state = {
		criteria: {},
		fromLetter: get(qs.parse(this.props.location.search), 'fromLetter')
	}

	setCriteria = (criteria) => {
		if (this.state.resetting) {
			this.setState({
				resetting: false
			})
		} else {
			this.setState({
				criteria: criteria,
				fromLetter: null
			})
		}
	}

	bootstrapForm = (criteria) => {
		if (!this.state.fromLetter) {
			this.setCriteria(criteria)
		}
	}

	setLetterSearch = (letter) => {
		if (this.form) {
			this.form.resetForm()
		}
		this.setState({
			fromLetter: letter,
			resetting: true
		})
	}

	render() {
		const locations = [...AppStore.locations]
		const { entriesReady } = this.props

		const layoutConfig = {
			pageTitle: 'Our Team',
			loading: !entriesReady,
			masthead: <StandardPageLayout.Masthead title="Our Team" backgroundImage={require('./masthead_bg.jpg')} />,
			preBody: (
				<div className={styles.searchWrapper}>
					<Container>
						<Headline tag="h2" theme="hd-3">
							Lawyer Search
						</Headline>
						<div className={classnames(styles.inner, Mq.mobile ? styles.stacked : styles.inline)}>
							<div className={classnames(styles.bucket, styles.form)}>
								<AttorneySearchForm
									onBootstrap={this.bootstrapForm}
									onChange={this.setCriteria}
									innerRef={(form) => {
										this.form = form
									}}
								>
									{({ Field }) => (
										<>
											<div className={styles.field}>
												<Field
													type="text"
													name="name"
													placeholder="Search by Name"
													className={styles.input}
												/>
											</div>
											<div className={styles.field}>
												<Field
													as={SelectInput}
													name="rank"
													options={RANKS}
													placeholder="Filter by Title"
													className={styles.input}
												/>
											</div>
											<div className={styles.field}>
												<Field
													as={SelectInput}
													name="locationId"
													options={locations}
													valueProp="id"
													labelProp="title"
													placeholder="Filter by Location"
													className={styles.input}
												/>
											</div>
											<div className={styles.field}>
												<Field
													as={SelectInput}
													name="practiceAreaId"
													options={this.props.practiceAreas}
													valueProp="id"
													labelProp="title"
													placeholder="Filter by Practice Area"
													className={styles.input}
												/>
											</div>
										</>
									)}
								</AttorneySearchForm>
							</div>
							<div className={classnames(styles.bucket, styles.atoz)}>
								<AttorneyAlphaNav
									onSelect={this.setLetterSearch}
									activeLetter={this.state.fromLetter}
								/>
							</div>
						</div>
					</Container>
				</div>
			)
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				{entriesReady &&
					(this.state.fromLetter ? (
						<Filter data={this.props.attorneys} criteria={{ fromLetter: this.state.fromLetter }}>
							{(filtered) => <FilteredAttorneyList attorneys={filtered} />}
						</Filter>
					) : (
						<FuseSearch
							data={this.props.attorneys}
							options={{ keys: ['title', 'firstName', 'lastName'] }}
							search={this.state.criteria.name}
						>
							{(fuseResults) => (
								<Filter data={fuseResults} criteria={this.state.criteria}>
									{(filtered) => <FilteredAttorneyList attorneys={filtered} />}
								</Filter>
							)}
						</FuseSearch>
					))}
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/our-team',
	exact: true
}

export default withCraftEntries(withRouter(OurTeam), {
	attorneys: {
		query: require('@/craft/queries/attornies.gql')
	},
	practiceAreas: {
		query: require('@/craft/queries/practiceAreas.gql')
	}
})
