import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import get from 'lodash/get'

import {
	StandardPageLayout,
	CraftEntries,
	RelatedArticles,
	Container,
	Headline,
	RichText,
	Button
} from '@/components/common'

import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'

function NotFoundRedirect() {
	return <Redirect to="/practices" />
}

class ServiceDetailPage extends React.PureComponent {
	static propTypes = {
		match: PropTypes.object.isRequired
	}

	render() {
		const slug = get(this.props.match, 'params.slug')

		return (
			<CraftEntries
				queries={{
					service: {
						query: require('@/craft/queries/practiceAreas.gql'),
						transform(services) {
							return services.find((s) => s.slug === slug)
						}
					}
				}}
				render={(isReady, { service }) =>
					isReady && !service ? (
						<NotFoundRedirect />
					) : (
						<StandardPageLayout
							pageTitle={isReady ? get(service, 'title') : null}
							loading={!isReady}
							masthead={
								<StandardPageLayout.Masthead
									title={get(service, 'title')}
									backgroundImage={require('./masthead_bg.jpg')}
								/>
							}
							children={
								<Container>
									{isReady && (
										<>
											{service.serviceContent.map((section) => (
												<div key={section.id} className={styles.section}>
													{section.headline && (
														<Headline tag="h2" theme="hd-2">
															{section.headline}
														</Headline>
													)}
													<RichText html={section.body} />
												</div>
											))}
											<div className={styles.clickOutCta}>
												<Button to={`/our-team?practiceAreaId=${service.id}`}>
													Meet our {service.title} Team Here
												</Button>
											</div>
											<RelatedArticles id={service.id} />
										</>
									)}
								</Container>
							}
						/>
					)
				}
			/>
		)
	}
}

export const routeConfig = {
	path: '/practices/:slug',
	exact: true
}

export default withRouter(ServiceDetailPage)
