import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
	uri: 'https://ropers.craftcms.me/api',
	cache: new InMemoryCache()
})

export async function Query(query) {
	const response = await client.query(query)
	return response.data.entries || response.data.categories || response.data.globalSet || response.data
}

export default client
