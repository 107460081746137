import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import AppStore from '@/store/app'
import Mq from '@/store/mq'
import chunk from 'lodash/chunk'
import moment from 'moment'

import Logo from '@/components/Logo'
import Container from '@/components/Container'

const SOCIAL_LINKS = {
	twitter: true,
	facebook: false,
	linkedIn: true
}

function Links(props) {
	return <ul className={styles.links} {...props} />
}

function SocialLinks() {
	return (
		<ul className={styles.socialLinks}>
			{SOCIAL_LINKS.twitter && (
				<li>
					<a href="https://twitter.com/ropersmajeski" target="_blank">
						<svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
							<path
								fill="#D4DDE7"
								fillRule="nonzero"
								d="M19 0c10.486 0 19 8.274 19 18.5S29.486 37 19 37C8.498 37 0 28.71 0 18.5 0 8.274 8.514 0 19 0Zm-2.938 10H11l6.637 9.451L11 27h1.5l5.803-6.6 4.635 6.6H28l-6.883-9.802L27.446 10h-1.5l-5.495 6.25L16.062 10Zm-.92 1 4.014 6.109.626.95L25 26h-2.142L18.6 19.52l-.625-.95L13 11h2.142Z"
							/>
						</svg>
					</a>
				</li>
			)}
			{SOCIAL_LINKS.facebook && (
				<li>
					<svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
						<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
							<g id="footer-mobile" transform="translate(-142.000000, -647.000000)" fillRule="nonzero">
								<g id="social-copy" transform="translate(88.000000, 647.000000)">
									<g id="Group" transform="translate(54.000000, 0.000000)">
										<path
											d="M19,0 C8.51438535,0 0,8.27419355 0,18.5 C0,28.7096774 8.4978204,37 19,37 C29.4856146,37 38,28.7258065 38,18.5 C38,8.27419355 29.5021796,0 19,0 Z"
											id="Path"
											fill="#D4DDE7"
										></path>
										<path
											d="M24,8.14345638 L24,11.1560403 L22.174475,11.1560403 C21.5121163,11.1560403 21.0597738,11.2994966 20.8336026,11.5704698 C20.6074313,11.841443 20.4943457,12.2558725 20.4943457,12.7978188 L20.4943457,14.9496644 L23.8869144,14.9496644 L23.4345719,18.3288591 L20.4943457,18.3288591 L20.4943457,27 L16.9563813,27 L16.9563813,18.3288591 L14,18.3288591 L14,14.9496644 L16.9563813,14.9496644 L16.9563813,12.4630872 C16.9563813,11.0444631 17.3602585,9.94463087 18.1680129,9.1635906 C18.9757674,8.38255034 20.0420032,8 21.3828756,8 C22.4975767,8.0159396 23.3699515,8.06375839 24,8.14345638 Z"
											id="Path"
											fill="#1E5A8E"
										></path>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</li>
			)}
			{SOCIAL_LINKS.linkedIn && (
				<li>
					<a href="https://www.linkedin.com/company/ropersmajeski" target="_blank">
						<svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1">
							<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<g
									id="footer-mobile"
									transform="translate(-196.000000, -647.000000)"
									fill="#D4DDE7"
									fillRule="nonzero"
								>
									<g id="social-copy" transform="translate(88.000000, 647.000000)">
										<path
											d="M127,37 C137.485615,37 146,28.7258065 146,18.5 C146,8.29032258 137.50218,0 127,0 C116.49782,0 108,8.27419355 108,18.5 C108,28.7096774 116.514385,37 127,37 Z M122.229294,26.8387097 L117.806452,26.8387097 L117.806452,13.8709677 L122.229294,13.8709677 L122.229294,26.8387097 Z M124.680907,13.8709677 L129.103749,13.8709677 L129.103749,15.7096774 C129.700087,14.8225806 130.743679,13.5645161 133.095902,13.5645161 C136.011334,13.5645161 138.197908,15.4193548 138.197908,19.4032258 L138.197908,26.8387097 L133.775065,26.8387097 L133.775065,19.9032258 C133.775065,18.1612903 133.129032,16.9677419 131.538797,16.9677419 C130.31299,16.9677419 129.584133,17.7741935 129.269398,18.5483871 C129.153444,18.8225806 129.120314,19.2096774 129.120314,19.5967742 L129.120314,26.8387097 L124.697472,26.8387097 C124.680907,26.8387097 124.730602,15.0806452 124.680907,13.8709677 Z M120.04272,7.61290323 C121.566696,7.61290323 122.494333,8.58064516 122.510898,9.85483871 C122.510898,11.0967742 121.550131,12.0967742 120.00959,12.0967742 L119.97646,12.0967742 C118.485615,12.0967742 117.524847,11.0967742 117.524847,9.85483871 C117.541412,8.58064516 118.53531,7.61290323 120.04272,7.61290323 Z"
											id="Shape"
										></path>
									</g>
								</g>
							</g>
						</svg>
					</a>
				</li>
			)}
		</ul>
	)
}

function LinksList({ links }) {
	return (
		<Links>
			{links.map((link) => (
				<li key={link.to}>
					<Link to={link.to}>{link.title}</Link>
				</li>
			))}
		</Links>
	)
}
LinksList.propTypes = {
	links: PropTypes.array.isRequired
}

@observer
class Footer extends React.Component {
	static defaultProps = {
		locations: []
	}

	static propTypes = {
		locations: PropTypes.array
	}

	render() {
		const locations = AppStore.locations
		const stackedLayout = !Mq.desktop

		const LocationsLinks = () => {
			if (!locations || !locations.length) {
				return null
			}
			const serializedLocations = locations.map((location) => ({
				title: location.title,
				to: `/locations/#${location.slug}`
			}))
			const middle = Math.ceil(serializedLocations.length / 2)
			const left = serializedLocations.slice(0, middle)
			const right = serializedLocations.slice(-(serializedLocations.length - middle))
			return (
				<>
					<LinksList links={left} />
					<LinksList links={right} />
				</>
			)
		}

		const primaryLinks = [
			{ to: '/our-firm', title: 'Our Firm' },
			{ to: '/our-team', title: 'Our Team' },
			{ to: '/practices', title: 'Practices' },
			{ to: '/locations', title: 'Locations' },
			{ to: '/news-insights', title: 'News & Insights' },
			{ to: '/careers', title: 'Careers' }
		]

		const legalLinks = [
			{ to: '/terms', title: 'Terms & Conditions' },
			{ to: '/privacy', title: 'Privacy Policy' }
		]

		return (
			<footer className={`${styles.footer} ${stackedLayout ? styles.stacked : styles.inline}`}>
				<Container>
					<div className={styles.upper}>
						<Logo inverse={true} />
						{!stackedLayout && <SocialLinks />}
					</div>
					{stackedLayout ? (
						<React.Fragment>
							<div className={styles.navWrapper}>
								<div className={`${styles.stackedSection} ${styles.mirrorList}`}>
									<h5 className={styles.headline}>Locations</h5>
									<LocationsLinks />
								</div>
								<div className={styles.stackedSection}>
									<LinksList links={primaryLinks} />
								</div>
								<div className={styles.stackedSection}>
									<LinksList links={legalLinks} />
									<SocialLinks />
								</div>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div className={styles.navWrapper}>
								<div>
									<h5 className={styles.headline}>Locations</h5>
									<div className={styles.navList}>
										<LocationsLinks />
									</div>
								</div>
								<div>
									<div className={styles.navList}>
										<LinksList links={primaryLinks} />
										<LinksList links={legalLinks} />
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
					<div className={styles.copyright}>
						© Copyright {moment().format('YYYY')} Ropers Majeski. All rights reserved.
					</div>
				</Container>
			</footer>
		)
	}
}

export default Footer
