import { observable } from 'mobx'

const AppStore = observable.object({
	overlayNavActive: false,
	locations: [],

	showOverlayNav() {
		this.overlayNavActive = true
	},

	hideOverlayNav() {
		this.overlayNavActive = false
	}
})

export default AppStore
