import React from 'react'
import Cx from 'classnames'
import Mq from '@/store/mq'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'

const HeadlineWithNav = observer(({ headline, navLink, className }) => (
	<div className={Cx(styles.headlineNav, className, { [styles.bordered]: Mq.mobile })}>
		{headline}
		{!Mq.mobile && <div className={styles.nav}>{navLink}</div>}
	</div>
))

export default HeadlineWithNav
