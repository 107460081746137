import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import { Link } from 'react-router-dom'
import { Container, Headline } from '@/components/common'
import Spinner from '@/components/Spinner'
import HeadlineWithNav from '@/components/HeadlineWithNav'
import ArticlesList from '@/components/ArticlesList'
import useFetchInsights from '@/components/useFetchInsights'
import useCraftQuery from '@/components/useCraftQuery'

function LatestNews({ featuredArticles }) {
	const [articlesPending, articles] = useCraftQuery({
		query: require('@/craft/queries/articles.gql'),
		variables: {
			id: featuredArticles.filter(({ typeHandle }) => typeHandle === 'articles').map(({ id }) => id)
		}
	})
	const [insightsPending, allInsights] = useFetchInsights()
	const pending = articlesPending || insightsPending
	const blended = React.useMemo(() => {
		if (!pending) {
			return featuredArticles.map(({ id, typeHandle, insightsShortCode }) => {
				switch (typeHandle) {
					case 'articles': {
						return articles.find((article) => article.id === id)
					}
					case 'insights': {
						return allInsights.find((insight) => insight.id === insightsShortCode)
					}
				}
			})
		}
	}, [pending])
	return (
		<div className={styles.wrapper}>
			<Container>
				<HeadlineWithNav
					headline={
						<Headline tag="h2" theme="hd-2">
							Featured Updates
						</Headline>
					}
					navLink={<Link to="/news-insights">View all News & Insights</Link>}
				/>
				<div className={styles.articles}>
					{pending ? (
						<div className={styles.spinner}>
							<Spinner />
						</div>
					) : (
						<ArticlesList articles={blended} />
					)}
				</div>
			</Container>
		</div>
	)
}

LatestNews.propTypes = {
	articles: PropTypes.array
}

export default LatestNews
