import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'
import Mq from '@/store/mq'

@observer
class Button extends React.Component {
	static defaultProps = {
		tag: 'button'
	}

	static propTypes = {
		tag: PropTypes.oneOf(['a', 'button']),
		to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	}

	render() {
		const { tag, ...passThroughProps } = this.props
		const TagName = this.props.to ? NavLink : tag
		const large = !Mq.mobile
		return <TagName className={`${styles.btn} ${large ? styles.large : ''}`} {...passThroughProps} />
	}
}

export default Button
