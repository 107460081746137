import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import videojs from 'video.js'

import 'video.js/dist/video-js.css'
import './video-js-theme.scss'

const AWS_URL = `https://d10t9tcfshapgv.cloudfront.net/videos`

function awsUrl(asset, ext) {
	return `${AWS_URL}/${asset}.${ext}`
}

class VideoPlayer extends React.PureComponent {
	static AWS = class extends React.PureComponent {
		static propTypes = {
			asset: PropTypes.string.isRequired
		}
		render() {
			return (
				<VideoPlayer controls={true} poster={awsUrl(this.props.asset, 'jpg')}>
					<source src={awsUrl(this.props.asset, 'mp4')} type="video/mp4" />
				</VideoPlayer>
			)
		}
	}

	state = {
		ready: false
	}

	componentDidMount() {
		this.player = videojs(this.el, {
			inactivityTimeout: 0,
			responsive: true,
			fluid: true
		})
	}

	componentWillUnmount() {
		if (this.player && this.player.dispose) {
			this.player.dispose()
		}
	}

	render() {
		const wrapperClassnames = classnames('video-js')
		return (
			<video
				className={wrapperClassnames}
				preload="auto"
				ref={(el) => {
					this.el = el
				}}
				{...this.props}
			/>
		)
	}
}

export default VideoPlayer
