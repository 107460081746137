import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import qs from 'query-string'

import StandardPageLayout from '@/components/StandardPageLayout'
import { Container } from '@/components/common'
import ArticlesList from '@/components/ArticlesList'
import useFetchInsights from '@/components/useFetchInsights'

import withCraftEntries from '@/components/withCraftEntries'

import { get, orderBy } from 'lodash'

class CategoryFilter extends React.PureComponent {
	static propTypes = {
		categoryId: PropTypes.string,
		articles: PropTypes.array,
		render: PropTypes.func
	}
	render() {
		let filtered = this.props.articles
		if (this.props.categoryId) {
			filtered = this.props.articles.filter((article) => {
				const articleCategoryId = get(article, 'articleCategory[0].id')
				return articleCategoryId === this.props.categoryId
			})
		}
		return this.props.render(filtered)
	}
}

function ArticlesPage({ location, entriesReady, articles = [], categories = [] }) {
	const [insightsLoading, insights] = useFetchInsights()
	const categorySlug = React.useMemo(() => {
		const queryParams = qs.parse(location.search)
		return queryParams.category
	}, [location.search])
	const category = React.useMemo(() => {
		return categories.find((c) => categorySlug === c.slug)
	}, [categorySlug, categories])
	const mastheadTitle = React.useMemo(() => {
		if (!entriesReady) {
			return null
		}
		if (category) {
			return category.title
		}
		return 'News & Insights'
	}, [entriesReady, categorySlug])

	if (entriesReady && categorySlug && !category) {
		return <Redirect to="/news-insights" />
	}

	const layoutConfig = {
		pageTitle: mastheadTitle,
		loading: !entriesReady || insightsLoading,
		masthead: <StandardPageLayout.Masthead title={mastheadTitle} backgroundImage={require('./masthead_bg.jpg')} />
	}

	const mergeArticles = [...insights, ...articles]
	const orderedArticles = orderBy(mergeArticles, 'date', 'desc')

	return (
		<StandardPageLayout {...layoutConfig}>
			<Container>
				{entriesReady && (
					<CategoryFilter
						categoryId={get(category, 'id')}
						articles={orderedArticles}
						render={(filtered) => <ArticlesList articles={filtered} />}
					/>
				)}
			</Container>
		</StandardPageLayout>
	)
}

ArticlesPage.propTypes = {
	location: PropTypes.object,
	entriesReady: PropTypes.bool.isRequired,
	articles: PropTypes.array,
	categories: PropTypes.array
}

export const routeConfig = {
	path: '/news-insights',
	exact: true
}

export default withCraftEntries(observer(ArticlesPage), {
	articles: {
		query: require('@/craft/queries/articles.gql')
	},
	categories: {
		query: require('@/craft/queries/articleCategories.gql')
	}
})
