import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'
import Headline from '@/components/Headline'
import Icon from '@/components/Icon'
import Nl2br from '@/components/Nl2br'
import Mq from '@/store/mq'

@observer
class LocationCard extends React.Component {
	static propTypes = {
		location: PropTypes.object.isRequired
	}

	render() {
		const large = !Mq.mobile
		const { location } = this.props

		const image = location.locationLeadImage[0]
		const mapAddress = location.fullAddress.replace(/\n/g, ', ')

		return (
			<div className={`${styles.location} ${large && styles.large}`}>
				<img src={image.url} alt={`${location.title}`} />
				<Headline tag="h4" theme="hd-4">
					{location.title}
				</Headline>
				<address className={styles.address}>
					<Nl2br text={location.fullAddress} />
					<ul className={styles.contactLinks}>
						<li>
							<i className={styles.icon}>
								<Icon name="phone" />
							</i>
							<a href={`tel:${location.officePhoneNumber}`}>{location.officePhoneNumber}</a>
						</li>
						{!!location.faxPhoneNumber && (
							<li>
								<i className={styles.icon}>
									<Icon name="fax" />
								</i>
								{location.faxPhoneNumber}
							</li>
						)}
						<li>
							<i className={styles.icon}>
								<Icon name="location" />
							</i>
							<a href={`https://www.google.com/maps/dir//${mapAddress}`} target="_blank">
								Directions
							</a>
						</li>
					</ul>
				</address>
			</div>
		)
	}
}

export default LocationCard
