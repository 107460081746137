import React from 'react'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'
import List from '@/components/List'
import LocationCard from '@/components/LocationCard'

@observer
class LocationsList extends React.PureComponent {
	render() {
		const itemsPerRow = (() => {
			if (Mq.mobile) {
				return 1
			}
			if (Mq.tablet) {
				return 2
			}
			return 4
		})()

		return (
			<List itemsPerRow={itemsPerRow} item={(location) => <LocationCard location={location} />} {...this.props} />
		)
	}
}

export default LocationsList
