import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './AppWrapper.module.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'

@observer
class AppWrapper extends React.PureComponent {
	static propTypes = {
		children: PropTypes.node
	}
	get classNames() {
		return classnames(styles.wrapper, {
			'screen-large': Mq.desktop,
			'screen-medium': Mq.tablet,
			'screen-small': Mq.mobile
		})
	}
	render() {
		return (
			<div className={this.classNames}>
				<Router>
					<div className={styles.body}>{this.props.children}</div>
				</Router>
			</div>
		)
	}
}

export default AppWrapper
