import React from 'react'
import styles from './styles.module.scss'

import Navigation from '@/components/Navigation'
import Container from '@/components/Container'

const Header = () => {
	return (
		<div className={styles.header}>
			<Container>
				<Navigation />
			</Container>
		</div>
	)
}

export default Header
