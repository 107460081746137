import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'
import {
	CraftEntries,
	StandardPageLayout,
	Container,
	Headline,
	RichText,
	RelatedArticles,
	MarkdownText
} from '@/components/common'
import { NavLink, Redirect } from 'react-router-dom'
import { attorneyToAttorneyItem, attorneyIconData, attorneyContact, TITLE_FIELDS } from '@/components/AttorneyHelper'
import AttorneyHeadshot from '@/components/AttorneyHeadshot'
import FuseSearch from '@/components/FuseSearch'
import attorneyQuery from '@/craft/queries/attornies'
import Nl2br from '@/components/Nl2br'

class NotFoundRouter extends React.PureComponent {
	static propTypes = {
		attorneys: PropTypes.array,
		onMatched: PropTypes.func,
		slug: PropTypes.string.isRequired
	}
	render() {
		return (
			<FuseSearch
				data={this.props.attorneys}
				options={{ keys: ['slug'], threshold: 0.3 }}
				search={this.props.slug}
			>
				{(fuseResults) => {
					return this.props.onMatched(fuseResults[0])
				}}
			</FuseSearch>
		)
	}
}

@observer
class AttorneySlugRoute extends React.PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				slug: PropTypes.string.isRequired
			})
		})
	}

	render() {
		const { slug } = this.props.match.params
		const responsiveClass = Mq.mobile ? styles.stacked : styles.inline

		return (
			<CraftEntries
				queries={{
					attorneys: {
						query: attorneyQuery
					}
				}}
				render={(isReady, { attorneys }) => {
					const attorneyRaw = isReady ? attorneys.find((a) => a.slug === slug) : null
					if (isReady && !attorneyRaw) {
						return (
							<NotFoundRouter
								attorneys={attorneys}
								slug={slug}
								onMatched={(match) => {
									let path = `/our-team`
									path += match ? `/${match.slug}` : `?status=301`
									return <Redirect to={path} />
								}}
							/>
						)
					}
					const attorney = isReady ? attorneyToAttorneyItem(attorneyRaw) : null
					const primaryLocation = isReady ? attorney.locations[0] : null
					return (
						<StandardPageLayout
							pageTitle={isReady ? attorney.name : null}
							loading={!isReady}
							masthead={
								<Container>
									{isReady && (
										<div className={classnames(styles.masthead, responsiveClass)}>
											<div className={styles.headshot}>
												<AttorneyHeadshot src={attorney.headshot} alt={attorney.name} />
											</div>
											<div>
												<Headline tag="h1" theme="hd-1" className={styles.name}>
													{attorney.name}
												</Headline>
												<Headline tag="div" theme="hd-2" className={styles.rank}>
													{attorney.rank}
												</Headline>
												<div className={classnames(styles.printInfo, styles.printOnly)}>
													{primaryLocation && <Nl2br text={primaryLocation.fullAddress} />}
													{attorney.email && <div>{attorney.email}</div>}
													{attorney.directNumber && (
														<div>
															<span className={styles.phonePrefix}>d</span>{' '}
															{attorney.directNumber}
														</div>
													)}
													{attorney.mobileNumber && (
														<div>
															<span className={styles.phonePrefix}>m</span>{' '}
															{attorney.mobileNumber}
														</div>
													)}
												</div>
												<div className={styles.screenOnly}>
													<div className={styles.contactPrimary}>
														{attorneyIconData(attorney, { showMobile: true })}
													</div>
													<div className={styles.contactSecondary}>
														{attorneyContact(attorney, { stacked: false, print: true })}
													</div>
												</div>
											</div>
										</div>
									)}
								</Container>
							}
							children={
								<Container>
									{isReady && (
										<>
											<div className={classnames(styles.body, responsiveClass)}>
												<div className={styles.sidebar}>
													{attorney.practiceAreas.length && (
														<div className={styles.section}>
															<h4 className={styles.heading}>Practice Areas</h4>
															<ul className={styles.list}>
																{attorney.practiceAreas.map((practiceArea, idx) => (
																	<li key={idx}>
																		<NavLink to={`/practices/${practiceArea.slug}`}>
																			{practiceArea.title}
																		</NavLink>
																	</li>
																))}
															</ul>
														</div>
													)}
													{Object.entries(TITLE_FIELDS).map(([field, title]) => {
														if (attorney[field] && attorney[field].length) {
															return (
																<div key={field} className={styles.section}>
																	<h4 className={styles.heading}>{title}</h4>
																	<ul className={styles.list}>
																		{attorney[field].map((item, idx) => (
																			<li key={idx}>
																				<MarkdownText string={item} />
																			</li>
																		))}
																	</ul>
																</div>
															)
														}
														return null
													})}
												</div>
												<div className={styles.bio}>
													<RichText html={attorney.bio} />
												</div>
											</div>
											<div className={styles.articles}>
												<RelatedArticles id={attorneyRaw.id} insightsAuthor={attorney.email} />
											</div>
										</>
									)}
								</Container>
							}
						/>
					)
				}}
			/>
		)
	}
}

export const routeConfig = {
	path: '/our-team/:slug',
	exact: true
}

export default AttorneySlugRoute
