module.exports={
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "id"
            }
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "QueryArgument"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "slug"
            }
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "String"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "limit"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "Int"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "relatedTo"
            }
          },
          "type": {
            "kind": "ListType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "QueryArgument"
              }
            }
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "entries"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "section"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "articles",
                  "block": false
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "slug"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "slug"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "orderBy"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "articleDate DESC",
                  "block": false
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "limit"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "limit"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "relatedTo"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "relatedTo"
                  }
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "title"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "slug"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "InlineFragment",
                  "typeCondition": {
                    "kind": "NamedType",
                    "name": {
                      "kind": "Name",
                      "value": "articles_articles_Entry"
                    }
                  },
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "date"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "articleDate"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "endDate"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "articleEndDate"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "body"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "articleBody"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "typeCondition": {
                                "kind": "NamedType",
                                "name": {
                                  "kind": "Name",
                                  "value": "articleBody_richText_BlockType"
                                }
                              },
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "richText"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "typeHandle"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  }
                                ]
                              }
                            },
                            {
                              "kind": "InlineFragment",
                              "typeCondition": {
                                "kind": "NamedType",
                                "name": {
                                  "kind": "Name",
                                  "value": "articleBody_image_BlockType"
                                }
                              },
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "id"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "image"
                                    },
                                    "arguments": [],
                                    "directives": [],
                                    "selectionSet": {
                                      "kind": "SelectionSet",
                                      "selections": [
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "id"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        },
                                        {
                                          "kind": "Field",
                                          "name": {
                                            "kind": "Name",
                                            "value": "url"
                                          },
                                          "arguments": [],
                                          "directives": []
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "typeHandle"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "alias": {
                          "kind": "Name",
                          "value": "source"
                        },
                        "name": {
                          "kind": "Name",
                          "value": "articleSource"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "articleCategory"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "title"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "InlineFragment",
                              "typeCondition": {
                                "kind": "NamedType",
                                "name": {
                                  "kind": "Name",
                                  "value": "articles_Category"
                                }
                              },
                              "directives": [],
                              "selectionSet": {
                                "kind": "SelectionSet",
                                "selections": [
                                  {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "label"
                                    },
                                    "arguments": [],
                                    "directives": []
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "articleLeadImage"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "title"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "url"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "alias": {
                                "kind": "Name",
                                "value": "thumb"
                              },
                              "name": {
                                "kind": "Name",
                                "value": "url"
                              },
                              "arguments": [],
                              "directives": [
                                {
                                  "kind": "Directive",
                                  "name": {
                                    "kind": "Name",
                                    "value": "transform"
                                  },
                                  "arguments": [
                                    {
                                      "kind": "Argument",
                                      "name": {
                                        "kind": "Name",
                                        "value": "handle"
                                      },
                                      "value": {
                                        "kind": "StringValue",
                                        "value": "articleThumb",
                                        "block": false
                                      }
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "Field",
                              "alias": {
                                "kind": "Name",
                                "value": "searchResultThumbUrl"
                              },
                              "name": {
                                "kind": "Name",
                                "value": "url"
                              },
                              "arguments": [],
                              "directives": [
                                {
                                  "kind": "Directive",
                                  "name": {
                                    "kind": "Name",
                                    "value": "transform"
                                  },
                                  "arguments": [
                                    {
                                      "kind": "Argument",
                                      "name": {
                                        "kind": "Name",
                                        "value": "handle"
                                      },
                                      "value": {
                                        "kind": "StringValue",
                                        "value": "searchResult",
                                        "block": false
                                      }
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "excerpt"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "attorneys"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "title"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "slug"
                              },
                              "arguments": [],
                              "directives": []
                            }
                          ]
                        }
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "practiceAreas"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "title"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "slug"
                              },
                              "arguments": [],
                              "directives": []
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 949
  }
};