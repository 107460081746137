import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

class RichText extends React.Component {
	static propTypes = {
		html: PropTypes.string,
		children: PropTypes.node
	}

	render() {
		const { html, ...passThroughProps } = this.props
		if (this.props.html) {
			return <div {...passThroughProps} className={styles.richText} dangerouslySetInnerHTML={{ __html: html }} />
		}
		return (
			<div {...passThroughProps} className={styles.richText}>
				{this.props.children}
			</div>
		)
	}
}

export default RichText
