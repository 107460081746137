import React from 'react'

async function fetchInsights({ author }) {
	const url = new URL('https://www.ropers.com/api/insights')
	if (author) {
		url.searchParams.set('author', author)
	}
	const insightsRequest = await fetch(url.toString())
	const insights = await insightsRequest.json()
	return insights
}

const useFetchInsights = ({ author } = {}) => {
	const [insights, setInsights] = React.useState([])
	const [pending, setPending] = React.useState(true)
	React.useEffect(() => {
		fetchInsights({ author })
			.then((insights) => {
				setInsights(
					insights.map((post) => {
						return {
							...post,
							isPassle: true
						}
					})
				)
			})
			.finally(() => {
				setPending(false)
			})
	}, [])
	return [pending, insights]
}

export default useFetchInsights
