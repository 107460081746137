import React from 'react'
import PropTypes from 'prop-types'
import { take } from 'lodash'
import styles from './styles.module.scss'

class List extends React.Component {
	static defaultProps = {
		itemsPerRow: 1
	}

	static propTypes = {
		itemsPerRow: PropTypes.number,
		items: PropTypes.array.isRequired,
		item: PropTypes.func.isRequired,
		limit: PropTypes.oneOfType([PropTypes.number, PropTypes.func])
	}

	render() {
		const { items, item, itemsPerRow } = this.props
		const stackedLayout = itemsPerRow === 1
		const limit = (() => {
			if (typeof this.props.limit === 'function') {
				return this.props.limit(this.props)
			}
			if (typeof this.props.limit === 'number') {
				return this.props.limit
			}
		})()
		const limitedItems = limit ? take(items, limit) : items
		return (
			<div className={`${styles.list} ${stackedLayout ? styles.stackedLayout : ''}`} col={itemsPerRow}>
				{limitedItems.map((data, index) => (
					<div key={index} className={styles.col}>
						{item(data)}
					</div>
				))}
			</div>
		)
	}
}

export default List
