import React from 'react'

export default function () {
	return (
		<svg width="18px" height="12px" viewBox="0 0 18 12" version="1.1">
			<defs>
				<path
					d="M8.07476636,14.4 L0.448598131,6.77383178 C-0.0785046729,6.24672897 -0.0785046729,5.39439252 0.448598131,4.8728972 L1.71588785,3.60560748 C2.24299065,3.07850467 3.0953271,3.07850467 3.61682243,3.60560748 L9.02242991,9.01121495 L14.4280374,3.60560748 C14.9551402,3.07850467 15.8074766,3.07850467 16.328972,3.60560748 L17.5962617,4.8728972 C18.1233645,5.4 18.1233645,6.25233645 17.5962617,6.77383178 L9.97009346,14.4 C9.45420561,14.9271028 8.60186916,14.9271028 8.07476636,14.4 L8.07476636,14.4 Z"
					id="path-1"
				></path>
			</defs>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="" fillRule="evenodd">
				<g id="more" transform="translate(-56.000000, -9.000000)">
					<g id="Group" transform="translate(0.000000, -4.000000)">
						<g id="Solid/angle-down" transform="translate(56.000000, 10.000000)">
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1"></use>
							</mask>
							<g id="Mask"></g>
							<g id="Color/Black" mask="url(#mask-2)" fill="" fillRule="nonzero">
								<rect id="Black" x="0" y="0" width="18" height="18"></rect>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
