import React from 'react'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'
import List from '@/components/List'
import ServiceCard from '@/components/ServiceCard'
import { NavLink } from 'react-router-dom'

@observer
class PracticeAreasList extends React.PureComponent {
	render() {
		const itemsPerRow = (() => {
			if (Mq.mobile) {
				return 1
			}
			if (Mq.tablet) {
				return 2
			}
			return 3
		})()

		return (
			<List
				itemsPerRow={itemsPerRow}
				item={(scope) => (
					<NavLink to={`/practices/${scope.slug}`}>
						<ServiceCard title={scope.title} image={scope.practiceAreaLeadImage[0].url} />
					</NavLink>
				)}
				{...this.props}
			/>
		)
	}
}

export default PracticeAreasList
