import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import get from 'lodash/get'
import Mq from '@/store/mq'
import moment from 'moment'

import List from '@/components/List'
import ArticleCard from '@/components/ArticleCard'

function formatDate(stamp) {
	return moment(stamp).format('MMMM DD, YYYY')
}

function articleToArticleCard(article) {
	if (article.isPassle) {
		return {
			title: article.title,
			label: 'Insight',
			thumbUrl: article.imageUrl,
			dateStamp: formatDate(article.date),
			excerpt: article.excerpt,
			to: article.url
		}
	}
	const category = get(article, 'articleCategory[0]')
	const thumbUrl = get(article, 'articleLeadImage[0].thumb')
	const dateStamp = (() => {
		if (!article.date) {
			return null
		}
		let stamp = formatDate(article.date)
		if (article.endDate) {
			stamp += ` - ${formatDate(article.endDate)}`
		}
		return stamp
	})()
	return {
		title: article.title,
		label: category.label,
		thumbUrl,
		dateStamp,
		source: article.source,
		excerpt: article.excerpt,
		to: `/news-insights/${article.slug}`
	}
}

@observer
class ArticlesList extends React.PureComponent {
	static Stacker = class extends React.PureComponent {
		render() {
			return (
				<List
					itemsPerRow={1}
					items={this.props.articles}
					item={(article) => (
						<ArticleCard {...articleToArticleCard(article)} horz={true} label={false} readmore={false} />
					)}
				/>
			)
		}
	}

	static propTypes = {
		articles: PropTypes.array
	}

	render() {
		const { articles, ...props } = this.props
		const itemsPerRow = (() => {
			if (Mq.desktop) {
				return 3
			}
			if (Mq.tablet) {
				return 2
			}
			return 1
		})()
		const config = {
			itemsPerRow: itemsPerRow,
			items: articles
		}
		return <List {...config} item={(article) => <ArticleCard {...articleToArticleCard(article)} />} {...props} />
	}
}

export default ArticlesList
