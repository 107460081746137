import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import qs from 'query-string'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'

const alphaArray = [...Array(26)].map((v, i) => (10 + i).toString(36))

function AttorneyAlphaNav({ className, onSelect, activeLetter }) {
	return (
		<div className={classnames(styles.wrapper, className)}>
			<div className={styles.header}>Search A-Z</div>
			<ol className={styles.alphaList}>
				{alphaArray.map((letter) => (
					<li key={letter} className={classnames({ [styles.active]: activeLetter === letter })}>
						{onSelect ? (
							<button
								onClick={() => {
									onSelect(letter)
								}}
							>
								{letter}
							</button>
						) : (
							<NavLink to={{ pathname: `/our-team`, search: qs.stringify({ fromLetter: letter }) }}>
								{letter}
							</NavLink>
						)}
					</li>
				))}
			</ol>
		</div>
	)
}

AttorneyAlphaNav.propTypes = {
	className: PropTypes.string,
	onSelect: PropTypes.func,
	activeLetter: PropTypes.string
}

export default AttorneyAlphaNav
