import React from 'react'

export default function () {
	return (
		<svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1">
			<defs>
				<path
					d="M5,5.90625 L5,19.03125 C5,20.1185742 4.16054688,21 3.125,21 L1.875,21 C0.839453125,21 0,20.1185742 0,19.03125 L0,5.90625 C0,4.81892578 0.839453125,3.9375 1.875,3.9375 L3.125,3.9375 C4.16054688,3.9375 5,4.81892578 5,5.90625 Z M20,8.53125 L20,19.03125 C20,20.1185742 19.1605469,21 18.125,21 L7.5,21 C6.46445313,21 5.625,20.1185742 5.625,19.03125 L5.625,1.640625 C5.625,0.734548828 6.32457031,0 7.1875,0 L15.2903125,0 C15.7047145,0 16.1021447,0.172845557 16.3951562,0.480539063 L18.2923828,2.47262695 C18.5853894,2.78029339 18.7499987,3.19757253 18.75,3.63267187 L18.75,6.67496484 C19.4781641,6.94529883 20,7.67410547 20,8.53125 Z M7.5,7.875 L16.875,7.875 L16.875,3.9375 L15.9375,3.9375 C15.4217578,3.9375 15,3.4946543 15,2.953125 L15,1.96875 L7.5,1.96875 L7.5,7.875 Z M11.25,16.2421875 C11.25,15.970377 11.0401172,15.75 10.78125,15.75 L9.21875,15.75 C8.95988281,15.75 8.75,15.970377 8.75,16.2421875 L8.75,17.8828125 C8.75,18.154623 8.95988281,18.375 9.21875,18.375 L10.78125,18.375 C11.0401172,18.375 11.25,18.154623 11.25,17.8828125 L11.25,16.2421875 Z M11.25,10.9921875 C11.25,10.720377 11.0401172,10.5 10.78125,10.5 L9.21875,10.5 C8.95988281,10.5 8.75,10.720377 8.75,10.9921875 L8.75,12.6328125 C8.75,12.904623 8.95988281,13.125 9.21875,13.125 L10.78125,13.125 C11.0401172,13.125 11.25,12.904623 11.25,12.6328125 L11.25,10.9921875 Z M16.25,16.2421875 C16.25,15.970377 16.0401172,15.75 15.78125,15.75 L14.21875,15.75 C13.9598828,15.75 13.75,15.970377 13.75,16.2421875 L13.75,17.8828125 C13.75,18.154623 13.9598828,18.375 14.21875,18.375 L15.78125,18.375 C16.0401172,18.375 16.25,18.154623 16.25,17.8828125 L16.25,16.2421875 Z M16.25,10.9921875 C16.25,10.720377 16.0401172,10.5 15.78125,10.5 L14.21875,10.5 C13.9598828,10.5 13.75,10.720377 13.75,10.9921875 L13.75,12.6328125 C13.75,12.904623 13.9598828,13.125 14.21875,13.125 L15.78125,13.125 C16.0401172,13.125 16.25,12.904623 16.25,12.6328125 L16.25,10.9921875 Z"
					id="fax_path-1"
				></path>
			</defs>
			<g id="fax_Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Locations" transform="translate(-23.000000, -1522.000000)">
					<g id="Group-9-Copy" transform="translate(20.000000, 1127.000000)">
						<g id="fax-icon" transform="translate(0.000000, 395.000000)">
							<g id="Solid/fax" transform="translate(3.250000, 0.000000)">
								<mask id="fax_mask-2" fill="white">
									<use xlinkHref="#fax_path-1"></use>
								</mask>
								<use id="Mask" fill="#000000" fillRule="nonzero" xlinkHref="#fax_path-1"></use>
								<g id="Color/Purple" mask="url(#fax_mask-2)" fill="#02355C" fillRule="nonzero">
									<rect id="Purple" x="0" y="0" width="20" height="21"></rect>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
