import React from 'react'

export default function () {
	return (
		<svg width="27px" height="21px" viewBox="0 0 27 21" version="1.1">
			<defs>
				<path
					d="M24.7288818,-2.55795385e-13 L2.18261719,-2.55795385e-13 C1.1219029,-2.55795385e-13 0,1.48262804 0,2.31700304 L0,18.6973018 C0,19.5316768 1.1219029,21 2.18261719,21 L24.7288818,21 C25.7895961,21 27,19.5316768 27,18.6973018 L27,2.31700304 C27,1.48262804 25.7895961,-2.55795385e-13 24.7288818,-2.55795385e-13 Z M6.75,17.5 L6.75,7 L10.125,7 L10.125,17.5 L6.75,17.5 Z M6.75,4.08333333 C6.75,3.11969697 7.5038961,2.33333333 8.4375,2.33333333 C9.36672078,2.33333333 10.125,3.11969697 10.125,4.08333333 C10.125,5.05151515 9.3711039,5.83333333 8.4375,5.83333333 C7.5038961,5.83333333 6.75,5.0469697 6.75,4.08333333 Z M17.3658301,17.5 L17.3658301,12.5159744 C17.3658301,11.327476 17.344112,9.79872204 15.867278,9.79872204 C14.3643822,9.79872204 14.1341699,11.0926518 14.1341699,12.4297125 L14.1341699,17.5 L11.25,17.5 L11.25,7.25399361 L14.0168919,7.25399361 L14.0168919,8.65335463 L14.0559846,8.65335463 C14.4425676,7.84824281 15.3851351,7 16.7881274,7 C19.7070463,7 20.25,9.12300319 20.25,11.8833866 L20.25,17.5 L17.3658301,17.5 Z"
					id="linkedIn_path-1"
				></path>
			</defs>
			<g id="linkedIn_Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Ropers-Attorney-Bio" transform="translate(-494.000000, -440.000000)">
					<g id="attorney-icons" transform="translate(410.000000, 440.000000)">
						<g id="Group">
							<g id="linkedin" transform="translate(84.000000, 0.000000)">
								<mask id="linkedIn_mask-2" fill="white">
									<use xlinkHref="#linkedIn_path-1"></use>
								</mask>
								<use
									id="linkedIn_Mask"
									fill="#02355C"
									fillRule="nonzero"
									xlinkHref="#linkedIn_path-1"
								></use>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
