import React from 'react'
import Glyph from './Glyph'
import styles from './styles.module.scss'

class SearchInput extends React.PureComponent {
	render() {
		return (
			<div className={styles.searchInput}>
				<Glyph className={styles.glyph} />
				<input type="search" {...this.props} />
			</div>
		)
	}
}

export default SearchInput
