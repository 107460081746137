import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'
import AppStore from '@/store/app'
import { Query } from '@/craft'
import LocationsQuery from '@/craft/queries/locations.gql'
import qs from 'query-string'

import GAPageView from '@/components/GAPageView'
import { Helmet } from '@/components/common'
import AppWrapper from '@/components/AppWrapper'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import OverlayNav from '@/components/OverlayNav'
import ScrollToTop from '@/components/ScrollToTop'
import Routes from '@/routes'

function withPrerenderStatusCode(Component) {
	return class extends React.PureComponent {
		static propTypes = {
			location: PropTypes.shape({
				search: PropTypes.string
			})
		}
		render() {
			const params = qs.parse(this.props.location.search)
			return (
				<>
					<Helmet>{params.status && <meta name="prerender-status-code" content={params.status} />}</Helmet>
					<Component {...this.props} />
				</>
			)
		}
	}
}

@observer
class App extends React.Component {
	async componentDidMount() {
		AppStore.locations = await Query({
			query: LocationsQuery
		})
	}

	render() {
		return (
			<AppWrapper>
				<GAPageView />
				<Helmet titleTemplate="%s :: Ropers Majeski" />
				<ScrollToTop>
					<OverlayNav active={AppStore.overlayNavActive} />
					<Header title="page title" />
					<Switch>
						{Routes.map((route) => (
							<Route
								{...route.routeConfig}
								component={withPrerenderStatusCode(route.default)}
								key={route.routeConfig.path}
							/>
						))}
					</Switch>
					<Footer />
				</ScrollToTop>
			</AppWrapper>
		)
	}
}

export default App
