import React from 'react'

export default function () {
	return (
		<svg width="27px" height="21px" viewBox="0 0 27 21" version="1.1">
			<defs>
				<path
					d="M16.3125,7 L15.75,7 L15.75,2.98743229 C15.7499988,2.75537726 15.6611101,2.53282629 15.5028867,2.36873437 L13.4658633,0.256265625 C13.3076318,0.0921821669 13.0930291,0 12.8692617,0 L3.09375,0 C2.62775391,0 2.25,0.391744792 2.25,0.875 L2.25,7 L1.6875,7 C0.755507812,7 0,7.78348958 0,8.75 L0,13.5625 C0,13.8041094 0.188894531,14 0.421875,14 L2.25,14 L2.25,17.7916667 C2.25,18.2749219 2.62775391,18.6666667 3.09375,18.6666667 L14.90625,18.6666667 C15.3722461,18.6666667 15.75,18.2749219 15.75,17.7916667 L15.75,14 L17.578125,14 C17.8111055,14 18,13.8041094 18,13.5625 L18,8.75 C18,7.78348958 17.2444922,7 16.3125,7 Z M13.5,16.3333333 L4.5,16.3333333 L4.5,12.8333333 L13.5,12.8333333 L13.5,16.3333333 Z M4.5,8.16666667 L4.5,2.33333333 L11.25,2.33333333 L11.25,3.79166667 C11.25,4.27291667 11.6296875,4.66666667 12.09375,4.66666667 L13.5,4.66666667 L13.5,8.16666667 L4.5,8.16666667 Z M15.1875,10.7916667 C14.7215391,10.7916667 14.34375,10.3998854 14.34375,9.91666667 C14.34375,9.43344792 14.7215391,9.04166667 15.1875,9.04166667 C15.6534609,9.04166667 16.03125,9.43344792 16.03125,9.91666667 C16.03125,10.3998854 15.6534609,10.7916667 15.1875,10.7916667 Z"
					id="print_path-1"
				></path>
			</defs>
			<g id="print_Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Ropers-Attorney-Bio" transform="translate(-536.000000, -440.000000)">
					<g id="attorney-icons" transform="translate(410.000000, 440.000000)">
						<g id="print-icon" transform="translate(126.000000, 0.000000)">
							<path
								d="M24.7288818,-2.55795385e-13 L2.18261719,-2.55795385e-13 C1.1219029,-2.55795385e-13 0,1.48262804 0,2.31700304 L0,18.6973018 C0,19.5316768 1.1219029,21 2.18261719,21 L24.7288818,21 C25.7895961,21 27,19.5316768 27,18.6973018 L27,2.31700304 C27,1.48262804 25.7895961,-2.55795385e-13 24.7288818,-2.55795385e-13 Z"
								id="Path"
								fill="#02355C"
								fillRule="nonzero"
							></path>
							<g
								id="Solid/print"
								strokeWidth="1"
								fillRule="evenodd"
								transform="translate(4.500000, 1.166667)"
							>
								<mask id="print_mask-2" fill="white">
									<use xlinkHref="#print_path-1"></use>
								</mask>
								<g id="print_Mask"></g>
								<g id="🎨-color" mask="url(#print_mask-2)" fill="#FFFFFF" fillRule="nonzero">
									<rect id="Black" x="0" y="0" width="18" height="18.6666667"></rect>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
