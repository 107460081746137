import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'
import Icon from '@/components/Icon'
import { compact, pick } from 'lodash'

export const RANKS = {
	PARTNER: 'Partner',
	OF_COUNSEL: 'Of Counsel',
	SENIOR_ASSOCIATE: 'Senior Associate',
	ASSOCIATE: 'Associate'
}

export const TITLE_FIELDS = {
	accolades: 'Accolades',
	courtAdmissions: 'Court Admissions',
	barAdmissions: 'Bar Admissions',
	education: 'Education',
	professionalActivities: 'Professional Activities',
	languages: 'Languages'
}

export function attorneyToAttorneyItem(attorney) {
	const headshot = attorney.attorneyHeadshot[0]
	const titleFields = Object.keys(TITLE_FIELDS).reduce((acc, field) => {
		if (attorney[field]) {
			acc[field] = compact(attorney[field].map((f) => f.title))
		}
		return acc
	}, {})
	return {
		...pick(attorney, 'id', 'slug', 'practiceAreas', 'locations'),
		name: attorney.title,
		email: attorney.emailAddress,
		linkedInUrl: attorney.linkedinUrl,
		directNumber: attorney.directPhoneNumber,
		mobileNumber: attorney.mobilePhoneNumber,
		rank: RANKS[attorney.attorneyBusinessRank],
		bio: attorney.attorneyBio,
		headshot: headshot ? headshot.url : null,
		...titleFields
	}
}

export function attorneyIconData(attorney, props = {}) {
	return (
		<>
			{attorney.locations.map((location, idx) => (
				<div key={idx} className={styles.iconText}>
					<div className={styles.icon}>
						<Icon name="location" />
					</div>
					{location.title}
				</div>
			))}
			{attorney.directNumber && (
				<div className={styles.iconText}>
					<div className={styles.icon}>
						<Icon name="phone" />
					</div>
					<a href={`tel:${attorney.directNumber}`}>{attorney.directNumber}</a>
				</div>
			)}
			{attorney.mobileNumber && props.showMobile && (
				<div className={styles.iconText}>
					<div className={styles.icon}>
						<Icon name="mobile" />
					</div>
					<a href={`tel:${attorney.mobileNumber}`}>{attorney.mobileNumber}</a>
				</div>
			)}
		</>
	)
}

attorneyIconData.propTypes = {
	showMobile: PropTypes.bool
}

export function attorneyContact(attorney, props = {}) {
	return (
		<div className={classnames(styles.contact, props.stacked ? styles.stacked : styles.inline)}>
			<a href={`mailto:${attorney.email}`}>
				<Icon name="email" />
			</a>
			{attorney.linkedInUrl && (
				<a href={`${attorney.linkedInUrl}`} target="_blank">
					<Icon name="linkedIn" />
				</a>
			)}
			{props.print && (
				<a href={`/our-team/${attorney.slug}/pdf`} target="_blank">
					<Icon name="print" />
				</a>
			)}
			<a href={`/our-team/${attorney.slug}/vcard`}>
				<Icon name="vcard" />
			</a>
		</div>
	)
}

attorneyContact.propTypes = {
	stacked: PropTypes.bool,
	print: PropTypes.bool
}
