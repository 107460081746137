import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { Helmet } from 'react-helmet'
import HomepageLayout from '@/components/HomepageLayout'
import Masthead from '@/components/Masthead'
import VideoPlayer from '@/components/VideoPlayer'
import { Container, Headline } from '@/components/common'
import withCraftEntries from '@/components/withCraftEntries'
import { observer } from 'mobx-react'
import AppStore from '@/store/app'

@observer
class Homepage extends React.PureComponent {
	static propTypes = {
		featuredArticles: PropTypes.array
	}

	render() {
		const offices = AppStore.locations.filter(({ ropersOffice }) => ropersOffice)
		const layoutConfig = {
			masthead: (
				<Masthead backgroundImage={require('./masthead_bg.jpg')}>
					<Container>
						<div className={styles.heroHeadline}>
							<h1>
								<span>
									<strong>Success</strong> is
								</span>
								<span>
									in our <strong>DNA</strong>
								</span>
							</h1>
							<div className={styles.text}>
								<p>
									For more than 70 years, Ropers Majeski has served the legal needs of
									our&nbsp;clients.
								</p>
								<p>We are fiercely proud of our&nbsp;heritage.</p>
							</div>
						</div>
					</Container>
				</Masthead>
			),
			upperContent: (
				<>
					{Boolean(offices.length) && (
						<>
							<Headline tag="h2" theme="hd-2">
								Our Experience
							</Headline>
							<p>
								For more than 70 years, Ropers Majeski has provided high-quality legal advice to a wide
								range of clients. With {offices.length} offices worldwide, we handle complex business
								challenges and provide sophisticated advice to companies and individuals.
							</p>
						</>
					)}
				</>
			),
			featuredArticles: this.props.featuredArticles,
			video: (
				<HomepageLayout.Video
					headline={
						<Headline tag="h2" theme="hd-2" underline={true}>
							Why clients choose Ropers&nbsp;Majeski
						</Headline>
					}
					video={<VideoPlayer.AWS asset="Ropers_Clients_Choose_Us_v6_1_28_22" />}
				/>
			)
		}

		return (
			<>
				<Helmet titleTemplate="">
					<title>Ropers Majeski</title>
				</Helmet>
				<HomepageLayout {...layoutConfig} />
			</>
		)
	}
}

export const routeConfig = {
	path: '/',
	exact: true
}

export default withCraftEntries(Homepage, {
	featuredArticles: {
		query: require('@/craft/queries/homepage'),
		transform(result) {
			return result.featuredArticles
		}
	}
})
