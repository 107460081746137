import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import { Container } from '@/components/common'

class HomepageVideo extends React.PureComponent {
	static propTypes = {
		headline: PropTypes.node,
		video: PropTypes.node
	}

	render() {
		return (
			<Container>
				<div className={styles.headline}>{this.props.headline}</div>
				<div className={styles.video}>{this.props.video}</div>
			</Container>
		)
	}
}

export default HomepageVideo
