import React from 'react'

export default function () {
	return (
		<svg width="27px" height="21px" viewBox="0 0 27 21" version="1.1">
			<g id="email_Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="Ropers-Attorney-Bio" transform="translate(-410.000000, -440.000000)" fill="#02355C">
					<g id="attorney-icons" transform="translate(410.000000, 440.000000)">
						<g id="Group">
							<g id="email-icon">
								<g id="Solid/envelope">
									<path
										d="M26.4884766,5.76770833 C26.6941406,5.59817708 27,5.75677083 27,6.02473958 L27,18.375 C27,19.8242188 25.8662109,21 24.46875,21 L2.53125,21 C1.13378906,21 0,19.8242188 0,18.375 L0,6.03020833 C0,5.75677083 0.300585937,5.60364583 0.511523437,5.77317708 C1.69277344,6.72473958 3.25898437,7.93333333 8.63789062,11.9856771 C9.75058594,12.8278646 11.6279297,14.5997396 13.5,14.5888021 C15.3826172,14.6052083 17.296875,12.7950521 18.3673828,11.9856771 C23.7462891,7.93333333 25.3072266,6.71927083 26.4884766,5.76770833 Z M13.5,12.8333333 C14.7234375,12.8552083 16.4847656,11.2364583 17.3707031,10.5692708 C24.3685547,5.30286458 24.9011719,4.84348958 26.5148437,3.53098958 C26.8207031,3.28489583 27,2.90208333 27,2.49739583 L27,2.625 C27,1.17578125 25.8662109,0 24.46875,0 L2.53125,0 C1.13378906,0 0,1.17578125 0,2.625 L0,2.49739583 C0,2.90208333 0.179296875,3.27942708 0.48515625,3.53098958 C2.09882812,4.83802083 2.63144531,5.30286458 9.62929687,10.5692708 C10.5152344,11.2364583 12.2765625,12.8552083 13.5,12.8333333 Z"
										id="email_Mask"
										fillRule="nonzero"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
