import React from 'react'
import PropTypes from 'prop-types'

import { observer } from 'mobx-react'
import AppStore from '@/store/app'

import StandardPageLayout from '@/components/StandardPageLayout'
import { Container } from '@/components/common'
import PracticeAreasList from '@/components/PracticeAreasList'
import { NavLink } from 'react-router-dom'

import withCraftEntries from '@/components/withCraftEntries'

const { LayoutSection } = StandardPageLayout

@observer
class PracticesPage extends React.PureComponent {
	static propTypes = {
		entriesReady: PropTypes.bool.isRequired,
		industries: PropTypes.array,
		practiceAreas: PropTypes.array
	}

	render() {
		const layoutConfig = {
			pageTitle: 'Practices',
			loading: !this.props.entriesReady,
			masthead: <StandardPageLayout.Masthead title="Practices" backgroundImage={require('./masthead_bg.jpg')} />
		}
		const offices = AppStore.locations.filter(({ ropersOffice }) => ropersOffice)
		return (
			<StandardPageLayout {...layoutConfig}>
				{this.props.entriesReady && (
					<Container>
						<LayoutSection>
							<p>
								Since our founding in Silicon Valley more than 70 years ago, Ropers Majeski has provided
								sophisticated counsel to clients across industries and disciplines. We offer a full
								range of legal advice, from complex litigation to high-value deals. Our lawyers know our
								clients’ businesses, offering strategic counseling and advice to avoid issues before
								they arise, and helping handle disputes when necessary.
							</p>
							{Boolean(offices.length) && (
								<p>
									With nearly 100 lawyers, Ropers Majeski serves its clients from{' '}
									<NavLink to="/locations">{offices.length} offices around the world</NavLink>.
								</p>
							)}
						</LayoutSection>
						<LayoutSection>
							<PracticeAreasList items={this.props.practiceAreas} />
						</LayoutSection>
					</Container>
				)}
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/practices',
	exact: true
}

export default withCraftEntries(PracticesPage, {
	practiceAreas: {
		query: require('@/craft/queries/practiceAreas.gql')
	}
})
