import React from 'react'
import { observer } from 'mobx-react'
import AppStore from '@/store/app'
import StandardPageLayout from '@/components/StandardPageLayout'
import { Container } from '@/components/common'
import LocationsList from '@/components/LocationsList'

@observer
class LocationsPage extends React.PureComponent {
	render() {
		const locations = [...AppStore.locations]

		const layoutConfig = {
			pageTitle: 'Locations',
			loading: !locations.length,
			masthead: <StandardPageLayout.Masthead title="Locations" backgroundImage={require('./masthead_bg.jpg')} />
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				<Container>
					<LocationsList items={locations} />
				</Container>
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/locations',
	exact: true
}

export default LocationsPage
