import React from 'react'
import CraftEntries from '@/components/CraftEntries'

export default function withCraftEntries(WrappedComponent, queries) {
	return class extends React.Component {
		render() {
			return (
				<CraftEntries
					queries={queries}
					render={(isReady, results) => (
						<WrappedComponent {...this.props} {...results} entriesReady={isReady} />
					)}
				/>
			)
		}
	}
}
