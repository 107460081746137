import React from 'react'
import PropTypes from 'prop-types'
import MarkdownIt from 'markdown-it'

const md = new MarkdownIt()

function renderMd(string) {
	let retVal = md.render(string)
	retVal = retVal.replace(/<p>/g, '').replace(/<\/p>/g, '')
	return retVal
}

class MarkdownText extends React.PureComponent {
	static propTypes = {
		string: PropTypes.string
	}

	render() {
		const rendered = renderMd(this.props.string)
		return <div dangerouslySetInnerHTML={{ __html: rendered }} />
	}
}

export default MarkdownText
