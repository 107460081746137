import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'

@observer
class Container extends React.Component {
	static defaultProps = {
		collapse: false
	}

	static propTypes = {
		collapse: PropTypes.bool,
		children: PropTypes.node
	}

	render() {
		const containerClass = this.props.collapse ? styles.collapse : styles.container
		return <div className={containerClass}>{this.props.children}</div>
	}
}

export default Container
