import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

class Debounce extends React.PureComponent {
	static propTypes = {
		ms: PropTypes.number.isRequired,
		func: PropTypes.func.isRequired,
		render: PropTypes.func.isRequired
	}

	state = {
		pending: false
	}

	debounced = debounce((...args) => {
		this.props.func(...args)
		this.setState({
			pending: false
		})
	}, this.props.ms)

	callDebounce = (...args) => {
		this.setState(
			{
				pending: true
			},
			() => {
				this.debounced(...args)
			}
		)
	}

	componentWillUnmount() {
		this.debounced.cancel()
	}

	render() {
		return this.props.render({
			debounced: this.callDebounce,
			pending: this.state.pending
		})
	}
}

export default Debounce
