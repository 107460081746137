import React from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'

import styles from './styles.module.scss'

import Mq from '@/store/mq'
import { Container, Headline, Button } from '@/components/common'
import AttorneyAlphaNav from '@/components/AttorneyAlphaNav'

export default observer(({ copy }) => {
	const stacked = !Mq.desktop

	const outerWrapperClassNames = classnames({
		[styles.inlineWrapper]: !stacked,
		[styles.stackedWrapper]: stacked
	})

	return (
		<div className={outerWrapperClassNames}>
			<Container>
				<div className={styles.wrapper}>
					<div className={classnames(styles.cell, styles.copy)}>
						<div>{copy}</div>
					</div>
					<div className={classnames(styles.cell, styles.attorneySearch)}>
						<div className={styles.upper}>
							<Headline tag="h3" theme="hd-3">
								Get the Conversation Started
							</Headline>
							<div className={styles.cta}>
								<Button to="/our-team">Find a Lawyer</Button>
							</div>
						</div>
						<div className={styles.lower}>
							<AttorneyAlphaNav />
						</div>
					</div>
				</div>
			</Container>
		</div>
	)
})
