import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'
import Mq from '@/store/mq'

@observer
class ServiceCard extends React.Component {
	static propTypes = {
		title: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired
	}

	render() {
		const large = !Mq.mobile
		const { title, image } = this.props

		return (
			<div className={styles.service}>
				<img src={image} alt={title} />
				<h2 className={classnames(styles.headline, { [styles.large]: large })}>{title}</h2>
			</div>
		)
	}
}

export default ServiceCard
