import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'
import { CraftEntries, StandardPageLayout, Container, Headline, RichText, RelatedArticles } from '@/components/common'
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { get, pick } from 'lodash'

function NotFoundRedirect() {
	return <Redirect to="/news-insights" />
}

function formatDate(stamp) {
	return moment(stamp).format('MMMM DD, YYYY')
}

function normalizeArticle(article) {
	const dateStamp = (() => {
		if (!article.date) {
			return null
		}
		let stamp = formatDate(article.date)
		if (article.endDate) {
			stamp += ` - ${formatDate(article.endDate)}`
		}
		return stamp
	})()
	return {
		...pick(article, ['id', 'slug', 'title', 'body']),
		category: article.articleCategory[0],
		leadImageUrl: get(article, 'articleLeadImage[0].url'),
		dateStamp
	}
}

@observer
class ArticleSlugRoute extends React.PureComponent {
	static propTypes = {
		match: PropTypes.shape({
			params: PropTypes.shape({
				slug: PropTypes.string.isRequired
			})
		})
	}

	render() {
		const { slug } = this.props.match.params
		const useMastheadHeadline = Mq.desktop

		return (
			<CraftEntries
				queries={{
					article: {
						query: require('@/craft/queries/articles'),
						variables: {
							slug
						},
						transform(articles) {
							return articles[0]
						}
					}
				}}
				render={(isReady, results = {}) => {
					if (isReady && !results.article) {
						return <NotFoundRedirect />
					}
					const article = isReady ? normalizeArticle(results.article) : null
					const Label = function () {
						return <div className={styles.label}>{article.category.title}</div>
					}
					return (
						<StandardPageLayout
							pageTitle={isReady ? article.title : null}
							loading={!isReady}
							masthead={
								isReady ? (
									<div
										className={classnames(styles.masthead, { [styles.large]: useMastheadHeadline })}
										style={{ backgroundImage: `url(${article.leadImageUrl})` }}
									>
										{useMastheadHeadline ? (
											<Container>
												<div className={styles.inner}>
													<Headline tag="h1" className={styles.headline}>
														{article.title}
													</Headline>
													<div className={styles.dateStamp}>{article.dateStamp}</div>
													<Label />
												</div>
											</Container>
										) : (
											<Label />
										)}
									</div>
								) : null
							}
							children={
								<Container>
									{isReady && (
										<div className={styles.body}>
											{!useMastheadHeadline && (
												<div className={styles.headlineWrapper}>
													<Headline tag="h1" className={styles.headline}>
														{article.title}
													</Headline>
													<div className={styles.dateStamp}>{article.dateStamp}</div>
												</div>
											)}
											{article.body.map(({ id, typeHandle, image, richText }) => (
												<React.Fragment key={id}>
													{typeHandle === 'richText' && <RichText key={id} html={richText} />}
													{typeHandle === 'image' && (
														<p>
															<img src={get(image, '[0].url')} className={styles.image} />
														</p>
													)}
												</React.Fragment>
											))}
											<div className={styles.related}>
												<RelatedArticles id={article.id} />
											</div>
										</div>
									)}
								</Container>
							}
						/>
					)
				}}
			/>
		)
	}
}

export const routeConfig = {
	path: '/news-insights/:slug',
	exact: true
}

export default ArticleSlugRoute
