import React from 'react'
import StandardPageLayout from '@/components/StandardPageLayout'
import { Container, RichText, Helmet } from '@/components/common'
const { LayoutSection } = StandardPageLayout

class PageNotFound extends React.PureComponent {
	render() {
		const layoutConfig = {
			pageTitle: 'Page Not Found',
			masthead: (
				<StandardPageLayout.Masthead
					title="Page Not Found"
					backgroundImage={require('./img/masthead_bg.jpg')}
				/>
			)
		}

		return (
			<StandardPageLayout {...layoutConfig}>
				<Helmet>
					<meta name="prerender-status-code" content="404" />
				</Helmet>
				<Container>
					<LayoutSection>
						<RichText>
							<p>The page you have requested does not exist. Return home.</p>
						</RichText>
					</LayoutSection>
				</Container>
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '*'
}

export default PageNotFound
