import React from 'react'
import PropTypes from 'prop-types'
import Fuse from 'fuse.js'

const defaultOptions = {
	tokenize: true,
	threshold: '.1'
}

class FuseSearch extends React.PureComponent {
	static propTypes = {
		data: PropTypes.array.isRequired,
		options: PropTypes.object,
		search: PropTypes.string,
		children: PropTypes.func
	}
	constructor(props) {
		super(props)
		this.fuse = new Fuse(props.data, {
			...defaultOptions,
			...props.options
		})
	}
	render() {
		const filtered = this.props.search ? this.fuse.search(this.props.search) : this.props.data
		return this.props.children(filtered)
	}
}

export function fuseSearch(data, query, options = {}) {
	const fuse = new Fuse(data, {
		...defaultOptions,
		...options
	})
	return query ? fuse.search(query) : data
}

export function useFuse({ data, query, options = {} }) {
	const fuse = React.useMemo(() => {
		return new Fuse(data, {
			...defaultOptions,
			...options
		})
	}, [])
	return query ? fuse.search(query) : data
}

export default FuseSearch
