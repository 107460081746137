import { extendObservable } from 'mobx'

class Mq {
	constructor(mqs) {
		for (const [screen, mq] of Object.entries(mqs)) {
			const mql = window.matchMedia(mq)
			extendObservable(this, {
				[screen]: mql.matches
			})
			mql.addListener((e) => {
				this[screen] = e.matches
			})
		}
	}
}

exports.Mq = Mq

export default new Mq({
	mobile: 'screen and (max-width: 767px)',
	tablet: 'screen and (min-width: 768px) and (max-width: 999px)',
	desktop: 'screen and (min-width: 1000px)'
})
