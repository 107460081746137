import React from 'react'
import PropTypes from 'prop-types'
import Velocity from 'velocity-animate'
import { withRouter } from 'react-router'

class ScrollToTop extends React.Component {
	static propTypes = {
		location: PropTypes.object.isRequired,
		children: PropTypes.node
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			Velocity(document.body, 'scroll', {
				duration: 300,
				easing: 'easeInOutExpo'
			})
		}
	}

	render() {
		return this.props.children
	}
}

export default withRouter(ScrollToTop)
