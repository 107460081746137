import React from 'react'
import StandardPageLayout from '@/components/StandardPageLayout'
import { Container, VideoPlayer } from '@/components/common'
const { LayoutSection } = StandardPageLayout

class Holiday2021 extends React.PureComponent {
	render() {
		return (
			<StandardPageLayout>
				<Container>
					<LayoutSection>
						<VideoPlayer.AWS asset="ropers-holiday2021" />
					</LayoutSection>
				</Container>
			</StandardPageLayout>
		)
	}
}

export const routeConfig = {
	path: '/holiday2021',
	exact: true
}

export default Holiday2021
