import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'

class AttorneyHeadshot extends React.PureComponent {
	static propTypes = {
		src: PropTypes.string,
		className: PropTypes.string
	}

	get src() {
		return this.props.src ? this.props.src : require('./placeholder.png')
	}

	render() {
		const { className, ...passThroughProps } = this.props
		return <img {...passThroughProps} src={this.src} className={classnames(styles.headshot, className)} />
	}
}

export default AttorneyHeadshot
