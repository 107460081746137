import React from 'react'
import PropTypes from 'prop-types'
import { CraftEntries, Headline, ArticlesList } from '@/components/common'
import styles from './styles.module.scss'
import { groupBy, get } from 'lodash'
import useFetchInsights from '@/components/useFetchInsights'

const RelatedArticles = (props) => {
	const [insightsLoading, insights] = useFetchInsights({ author: props.insightsAuthor })
	return (
		<CraftEntries
			queries={{
				articles: {
					query: require('@/craft/queries/articles.gql'),
					variables: {
						relatedTo: props.id
					}
				}
			}}
			render={(isReady, { articles: allArticles }) => {
				if (isReady && !insightsLoading) {
					const groups = groupBy(allArticles, (article) => get(article, 'articleCategory[0].title'))
					if (props.insightsAuthor && insights.length) {
						groups['Insights'] = insights
					}
					return Object.entries(groups).map(([category, articles]) => (
						<div key={category} className={styles.group}>
							<Headline tag="h3" theme="hd-3" underline={true}>
								{category}
							</Headline>
							<ArticlesList.Stacker articles={articles} />
						</div>
					))
				} else {
					return null
				}
			}}
		/>
	)
}

RelatedArticles.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	insightsAuthor: PropTypes.string
}

export default RelatedArticles
