import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import styles from './styles.module.scss'

import Mq from '@/store/mq'
import Container from '@/components/Container'

@observer
class Masthead extends React.Component {
	static defaultProps = {
		collapsed: false
	}

	static propTypes = {
		backgroundImage: PropTypes.string,
		title: PropTypes.string,
		children: PropTypes.node,
		collapsed: PropTypes.bool
	}

	render() {
		const { title, backgroundImage, children, collapsed } = this.props

		const wrapperClassNames = classnames(styles.masthead, {
			[styles.collapsed]: collapsed,
			[styles.large]: Mq.desktop
		})

		return (
			<div className={wrapperClassNames} style={{ backgroundImage: `url(${backgroundImage})` }}>
				<Container>
					{!collapsed && (children ? children : <h1 className={styles.pageTitle}>{title}</h1>)}
				</Container>
			</div>
		)
	}
}

export default Masthead
