import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { Headline, RichText, Button } from '@/components/common'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'

@observer
class Category extends React.PureComponent {
	static defaultProps = {
		reverse: false
	}

	static propTypes = {
		title: PropTypes.string.isRequired,
		image: PropTypes.string,
		blurb: PropTypes.node.isRequired,
		reverse: PropTypes.bool,
		cta: PropTypes.element
	}

	render() {
		const stacked = !Mq.desktop
		const wrapperClassNames = classnames(styles.category, stacked ? styles.stacked : styles.inline, {
			[styles.reverse]: this.props.reverse
		})
		return (
			<div className={wrapperClassNames}>
				<Headline tag="h2" theme="hd-2">
					{this.props.title}
				</Headline>
				<div className={styles.body}>
					{this.props.image && (
						<div className={styles.image}>
							<img src={this.props.image} />
						</div>
					)}
					<div className={styles.blurb}>
						<RichText>{this.props.blurb}</RichText>
						<div className={styles.cta}>
							{this.props.cta ? (
								this.props.cta
							) : (
								<Button
									tag="a"
									href="https://workforcenow.adp.com/jobs/apply/posting.html?client=RMKB&ccId=19000101_000001&type=MP&lang=en_US"
									target="_blank"
								>
									View Open Positions
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class Careers extends React.PureComponent {
	static Category = Category

	static propTypes = {
		children: PropTypes.node
	}

	render() {
		return this.props.children
	}
}

export default Careers
