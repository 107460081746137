import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'
import classnames from 'classnames'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'
import List from '@/components/List'
import Headline from '@/components/Headline'
import { attorneyToAttorneyItem, attorneyIconData, attorneyContact } from '@/components/AttorneyHelper'
import AttorneyHeadshot from '@/components/AttorneyHeadshot'
import { Link } from 'react-router-dom'
import { sortBy } from 'lodash'

@observer
class AttorneyItem extends React.PureComponent {
	static propTypes = {
		headshot: PropTypes.string,
		name: PropTypes.string,
		slug: PropTypes.string,
		rank: PropTypes.string,
		locations: PropTypes.array,
		directNumber: PropTypes.string,
		mobileNumber: PropTypes.string,
		email: PropTypes.string,
		linkedInUrl: PropTypes.string
	}

	render() {
		const href = `/our-team/${this.props.slug}`
		return (
			<div className={styles.attorney}>
				<div className={styles.headshot}>
					<Link to={href}>
						<AttorneyHeadshot src={this.props.headshot} alt={this.props.name} />
					</Link>
				</div>
				<div className={classnames(styles.lower, Mq.mobile ? styles.stacked : styles.inline)}>
					<div className={styles.primary}>
						<div className={styles.name}>
							<Link to={href}>
								<Headline tag="h4" className={styles.headline}>
									{this.props.name}
								</Headline>
								<Headline tag="h5" className={styles.rank}>
									{this.props.rank}
								</Headline>
							</Link>
						</div>
						{attorneyIconData(this.props)}
					</div>
					<div className={styles.secondary}>{attorneyContact(this.props, { stacked: !Mq.mobile })}</div>
				</div>
			</div>
		)
	}
}

@observer
class AttorneyList extends React.PureComponent {
	static propTypes = {
		attorneys: PropTypes.array
	}

	render() {
		const { attorneys, ...props } = this.props
		const itemsPerRow = (() => {
			if (Mq.desktop) {
				return 4
			}
			if (Mq.tablet) {
				return 2
			}
			return 1
		})()

		const config = {
			itemsPerRow: itemsPerRow,
			items: sortBy(attorneys, 'lastName')
		}
		return (
			<List {...config} item={(attorney) => <AttorneyItem {...attorneyToAttorneyItem(attorney)} />} {...props} />
		)
	}
}

export default AttorneyList
