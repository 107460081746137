import React from 'react'
import { Query } from '@/craft'

const useCraftQuery = (props) => {
	const [result, setResult] = React.useState()
	const [pending, setPending] = React.useState(true)
	React.useEffect(() => {
		Query(props)
			.then((result) => {
				setResult(result)
			})
			.finally(() => {
				setPending(false)
			})
	}, [])
	return [pending, result]
}

export default useCraftQuery
