import React from 'react'
import PropTypes from 'prop-types'

class Nl2br extends React.PureComponent {
	static propTypes = {
		text: PropTypes.string.isRequired
	}

	render() {
		return this.props.text.split('\n').map((line, idx) => (
			<React.Fragment key={idx}>
				{line}
				<br />
			</React.Fragment>
		))
	}
}

export default Nl2br
