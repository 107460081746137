//todo: clean this up from Nick dev

import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import Mq from '@/store/mq'
import styles from './styles.module.scss'

@observer
class Headline extends React.Component {
	static propTypes = {
		tag: PropTypes.string.isRequired,
		theme: PropTypes.oneOf(['hd-1', 'hd-2', 'hd-3', 'hd-4']),
		underline: PropTypes.bool,
		className: PropTypes.string,
		children: PropTypes.node
	}

	render() {
		const TagName = this.props.tag
		const Theme = this.props.theme

		const sizeLarge = Mq.desktop
		const sizeMedium = Mq.tablet

		const wrapperClassNames = classnames(styles.headline, this.props.className, {
			[styles.sizeLarge]: sizeLarge,
			[styles.sizeMedium]: sizeMedium,
			[styles.underline]: this.props.underline
		})

		return (
			<TagName className={wrapperClassNames} theme={Theme}>
				{this.props.children}
			</TagName>
		)
	}
}

export default Headline
