import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'
import { observer } from 'mobx-react'
import Mq from '@/store/mq'

import UpperContent from './children/UpperContent'
import LatestNews from './children/LatestNews'
import Video from './children/Video'

@observer
class HomepageLayout extends React.PureComponent {
	static Video = Video

	static propTypes = {
		masthead: PropTypes.node.isRequired,
		upperContent: PropTypes.node.isRequired,
		video: PropTypes.node.isRequired,
		featuredArticles: PropTypes.array
	}

	render() {
		const { masthead, upperContent, video, featuredArticles } = this.props
		return (
			<div className={classnames(styles.wrapper, { [styles.small]: Mq.mobile })}>
				{masthead}
				<UpperContent copy={upperContent} />
				{featuredArticles && <LatestNews featuredArticles={featuredArticles} />}
				{video}
			</div>
		)
	}
}

export default HomepageLayout
