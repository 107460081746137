import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'

class Columns extends React.Component {
	static defaultProps = {
		stacked: false
	}

	static propTypes = {
		stacked: PropTypes.bool,
		children: PropTypes.node
	}

	render() {
		const { stacked, children } = this.props
		const layoutClassNames = classnames(stacked ? styles.stacked : styles.inline)
		return <div className={layoutClassNames}>{children}</div>
	}
}

export default Columns
