import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import styles from './styles.module.scss'

@observer
class ReadMore extends React.Component {
	static defaultProps = {
		text: 'Read More'
	}

	static propTypes = {
		text: PropTypes.string
	}

	render() {
		const { text, ...props } = this.props

		return (
			<div {...props} className={styles.readMore}>
				{text}
			</div>
		)
	}
}

export default ReadMore
